import axios from "axios";

const URL = process.env.REACT_APP_URL || "";

export const ChargingStation = {
  Fetch_Charging_Stations: async (
    token,
    page,
    noOfRows,
    searchText,
    sort,
    orderType,
    status
  ) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/charging-stations/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText,
        sort,
        orderType,
        status,
      },
    });
  },
  Fetch_Charging_Stations_Count: async (token) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/chargingStations-count`, { headers });
  },
  Fetch_Charging_Stations_Stats: async (token) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/chargingStation-stats`, { headers });
  },
};
