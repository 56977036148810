import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "./styles.scss";

let dropdownStyle;

const ReactDropdown = ({
  dropdownValue,
  dropdownData,
  onDropdownSelect,
  isChargingStation,
  disabled,
  className,
}) => {
  const [dropdownVal, setDropdownVal] = useState("");

  if (className?.length) {
    dropdownStyle = `${className}`;
  } else {
    dropdownStyle = "dropdown-div";
  }
  let makDisable = false;
  if (disabled) {
    makDisable = true;
  }
  useEffect(() => {
    const itemIndex = dropdownData.findIndex((item) => {
      const label = item.label?.toLowerCase();
      const value = dropdownValue.toLowerCase();

      return label === value;
    });

    setDropdownVal(dropdownData[itemIndex]);
  }, [dropdownValue]);

  return (
    <Dropdown
      disabled={makDisable}
      value={dropdownVal}
      className={dropdownStyle}
      options={dropdownData}
      placeholder="Select"
      onChange={(item) => {
        onDropdownSelect(item.label, item.value);
      }}
      data-isChargingStation={isChargingStation}
    />
  );
};

export default ReactDropdown;
