import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "./types";

const initialState = {
  user: {
    id: null,
    role: null,
    name: null,
    email: null,
    country: null,
    team: {
      id: null,
    },
  },
  token: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: {},
        token: null,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...payload.user },
      };

    default:
      return state;
  }
};

export default authReducer;
