import React, { useState, useLayoutEffect, useEffect } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import PerformanceCard from "../../components/PerformanceCard";
import Icon from "../../components/Icon";
import { infoCardsData } from "../../mock";
import { useSelector } from "react-redux";
import { Location } from "../../services/Location";
import { ChargingStation } from "../../services/ChargingStation";
import Container from "../../components/Container";

const NetworkPerformance = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [showCards, setShowCards] = useState(true);
  const [screenWidth, setScreenWidth] = useState(null);
  const [locationCount, setLocationCount] = useState(0);
  const [chargingStationCount, setChargingStationCount] = useState(0);
  const [LocationProgress, setLocationProgress] = useState([]);
  const [ChargerProgress, setChargerProgress] = useState([]);

  useEffect(() => {
    fetchLocationsCount();
    fetchLocationsStats();
    chargingStationsCount();
    FetchChargingStations();
  }, []);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth <= 1635) {
        setShowCards(false);
      } else {
        setShowCards(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  // getting locations count where location status not equal to Deleted
  const fetchLocationsCount = () => {
    Location.Fetch_Locations_Count(token).then((res) => {
      setLocationCount(res.data);
    });
  };
  const chargingStationsCount = () => {
    ChargingStation.Fetch_Charging_Stations_Count(token).then((res) => {
      setChargingStationCount(res.data);
    });
  };
  const fetchLocationsStats = () => {
    let progress = { title: "", value: "", color: "" };
    Location.Fetch_Locations_Stats(token).then((res) => {
      const stats = res.data.stats;
      let tempLocationProgress = [
        { title: "Live", value: 0, color: "#76BC43" },
        { title: "In Construction", value: 0, color: "#FCB623" },
        { title: "In Planning", value: 0, color: "#FFFD78" },
        { title: "Out of Service", value: 0, color: "#DC143C" },
        { title: "Live", value: 0, color: "#76BC43" },
      ];
      for (let i = 0; i < stats.length; i++) {
        // progress.title = stats[i].type_status;
        if (stats[i].type_status === "live") {
          tempLocationProgress[0].value = stats[i].count / 2;
          tempLocationProgress[4].value = stats[i].count / 2;
        } else if (stats[i].type_status === "in construction") {
          tempLocationProgress[1].value = stats[i].count;
        } else if (stats[i].type_status === "in planning") {
          tempLocationProgress[2].value = stats[i].count;
        } else {
          tempLocationProgress[3].value += stats[i].count;
        }
      }
      setLocationProgress(tempLocationProgress);
    });
  };
  const FetchChargingStations = () => {
    ChargingStation.Fetch_Charging_Stations_Stats(token).then((res) => {
      const stats = res.data.stats;
      let tempChargerProgress = [
        { title: "Available", value: 0, color: "#005150" },
        { title: "Charging", value: 0, color: "#7AC243" },
        { title: "Unknown", value: 0, color: "#FDB624" },
        { title: "Available", value: 0, color: "#005150" },
      ];
      for (let i = 0; i < stats.length; i++) {
        if (stats[i].status === "AVAILABLE") {
          tempChargerProgress[0].value = stats[i].count / 2;
          tempChargerProgress[3].value = stats[i].count / 2;
        } else if (stats[i].status === "CHARGING") {
          tempChargerProgress[1].value = stats[i].count;
        } else {
          tempChargerProgress[2].value += stats[i].count;
        }
      }

      setChargerProgress(tempChargerProgress);
    });
  };
  const AnnouncementCards = ({ centerDiv, bottomDiv }) => {
    return (
      <div className="announcement-cards-div">
        {!bottomDiv && (
          <div className="announcement-card-1 announcement-card">
            <Icon icon={"marketing_circle"} />
            <div className="announcement-text">
              <p>Marketing</p>
              <p>Coming Soon</p>
            </div>
          </div>
        )}
        {!bottomDiv && (
          <div
            onClick={() => navigate(`/feed-notifications`)}
            className="announcement-card-2 announcement-card"
          >
            <Icon icon={"notification_circle"} />
            <p>Notifications</p>
          </div>
        )}

        {!centerDiv && (
          <div className="announcement-card-3 announcement-card">
            <Icon icon={"marketing_circle"} />
            <div className="announcement-text">
              <p>Marketing</p>
              <p>Coming Soon</p>
            </div>
          </div>
        )}
        {bottomDiv && (
          <div className="announcement-card-3 announcement-card">
            <Icon icon={"marketing_circle"} />
            <div className="announcement-text">
              <p>Marketing</p>
              <p>Coming Soon</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const InfoCards = () => {
    return (
      <div className="info-cards">
        {infoCardsData.map((item, ind) => {
          return (
            <div
              onClick={() => navigate(`/${item.link}`)}
              className="info-card"
            >
              <h6>{item.title}</h6>
              <img src={item.img} alt="" />
              <p>{item.para}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Container>
      <div className="network-container">
        <ActionHeader
          title={"Digital Network"}
          subTitle="Performance"
          showCalendar
        />
        <Divider />
        <div className="chart-content">
          {screenWidth <= 992 ? (
            <Carousel
              emulateTouch={true}
              infiniteLoop={true}
              showArrows={false}
            >
              <PerformanceCard
                title="Location Status"
                progressData={LocationProgress}
                count={locationCount}
              />
              <PerformanceCard
                title="Charger Status"
                progressData={ChargerProgress}
                count={chargingStationCount}
                tooltipText={true}
                id={1}
              />
            </Carousel>
          ) : (
            <>
              <PerformanceCard
                title="Location Status"
                progressData={LocationProgress}
                count={locationCount}
              />
              <PerformanceCard
                title="Charger Status"
                progressData={ChargerProgress}
                count={chargingStationCount}
                tooltipText={true}
                id={1}
              />
              {showCards && <AnnouncementCards />}
            </>
          )}
        </div>
        <div className="center-content">
          <div className="bg-img">
            <div className="bg-overlay">
              <div id={"icon-view-id"} className="icon-view">
                <Icon icon={"map_circle"} />
                <h6>Map</h6>
              </div>
              <div className="desc">
                {showCards ? (
                  <p>
                    The Network Management Suite allows
                    <br />
                    you to quickly view and manage
                    <br />
                    your locations with the help of
                    <br />
                    an easy to use and intuitive
                    <br />
                    interface.
                  </p>
                ) : (
                  <p>
                    The location manager allows you to quickly view and manage
                    <br />
                    your locations the help of an easy to use and intuitive
                    interface.
                  </p>
                )}
              </div>
            </div>
          </div>

          {showCards && <InfoCards />}
          {!showCards && <AnnouncementCards centerDiv />}
        </div>
        {!showCards ? (
          <div className="bottom-content">
            <InfoCards />
            <AnnouncementCards bottomDiv />
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default NetworkPerformance;
