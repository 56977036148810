import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import React, { useState } from "react";
import Calendar from "react-calendar";

const ReactCalendar = ({ date, setDate }) => {
  const [value, onChange] = useState(new Date());

  return (
    <div
      className="react-calendar"
      style={{
        position: "absolute",
        zIndex: 9999,
        top: "1rem",
        right: "1rem",
        bottom: 0,
      }}
    >
      <Calendar onChange={setDate} selectRange />
    </div>
  );
};

export default ReactCalendar;
