import axios from "axios";

const URL = process.env.REACT_APP_URL || '';

export const StatusRegister = {
  FetchStatusRegister: async (token, page, noOfRows, searchText, dates) => {
    const headers = { Authorization: token };

    return axios.get(`${URL}/api/v1/statusRegister/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText: searchText,
        startDate: dates[0],
        endDate: dates[1],
      },
    });
  },
};
