import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "./types";

export const setUserLogin = (data) => {
  return {
    type: LOGIN_USER,
    payload: data,
  };
};

export const setUserLogout = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const setUserData = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  };
};
