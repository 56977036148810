import React, { useEffect, useState } from "react";
import moment from "moment";

import "./styles.scss";
import SearchInput from "../SearchInput";
import Button from "../Button/index";
import ReactCalendar from "../ReactCalendar";
import Icon from "../Icon";

const ActionHeader = ({
  appLogo,
  bgColor,
  title,
  subTitle,
  showSearchBar,
  showCalendar,
  showButtons,
  leftButtonTitle,
  leftButtonClick,
  leftButtonDisable,
  rightButtonTitle,
  rightButtonClick,
  rightButtonDisable,
  setDates,
  dates,
  totalCount,
  filterStatus,
  onChange,
  inputValue,
}) => {
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [rangeDates, setRangeDates] = useState([
    `${moment().subtract(1, "days").format("YYYY-MM-DD")}T00:00:00.000Z`,
    `${moment().format("YYYY-MM-DD")}T23:59:59.999Z`,
  ]);
  useEffect(() => {
    if (dates) {
      setDates(rangeDates);
    }
  }, [rangeDates]);

  const isMonthSame =
    moment(rangeDates[0]).format("MMMM") ===
    moment(rangeDates[1]).format("MMMM");

  return (
    <header className="action-header">
      {showCalendarModal && (
        <ReactCalendar
          date={rangeDates}
          setDate={(value) => {
            setRangeDates(value);
            setShowCalendarModal(false);
          }}
        />
      )}
      <div className="title-div">
        {appLogo && <Icon icon="logo" />}
        <div className="title-heading">
          <h4 className="title" data-bg-color={bgColor}>
            {title}{" "}
          </h4>
          <p>
            {title === "Charging Stations" ? (
              <>
                ({totalCount}) Filtered on Connection:{" "}
                {filterStatus?.charAt(0).toUpperCase() + filterStatus.slice(1)}
              </>
            ) : (
              ""
            )}
          </p>
        </div>

        {subTitle?.length && (
          <>
            <h4 className="sub-title">{subTitle}</h4>
            <div className="arrows" style={{ marginTop: "0.7rem" }}></div>
          </>
        )}
      </div>
      {showButtons && (
        <div className="buttons-group">
          <Button
            title={leftButtonTitle}
            size={"sm"}
            onClick={leftButtonClick}
            disabled={leftButtonDisable}
          />

          {rightButtonTitle && (
            <Button
              title={rightButtonTitle}
              size={"sm"}
              onClick={() => (rightButtonClick ? rightButtonClick() : {})}
              disabled={rightButtonDisable}
            />
          )}
        </div>
      )}
      {showCalendar && (
        <div className="calendar" onClick={() => setShowCalendarModal(true)}>
          <div className="selected-date">
            <>
              <h4 className="month">{moment(rangeDates[0]).format("MMMM")}</h4>
              <h4 className="date">{moment(rangeDates[0]).format("DD")}-</h4>
            </>

            {!isMonthSame && (
              <h4 className="month">{moment(rangeDates[1]).format("MMMM")}</h4>
            )}

            <h4 className="date">{moment(rangeDates[1]).format("DD")}</h4>
          </div>
          <div className="arrows">
            <Icon icon={"up_arrow_small"} />
            <Icon icon={"down_arrow_small"} />
          </div>
        </div>
      )}
    </header>
  );
};

export default ActionHeader;
