import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "./style.scss";
import TextInput from "./../../components/TextInput/index";
import Button from "../../components/Button";
import Icon from "./../../components/Icon/index";
import electric_highway from "../../assets/electric-highway-logo.svg";
import { setUserLogin } from "./../../redux/auth/actions";
import { Auth } from "../../services/Auth";
import { setLoading } from "../../redux/sidebar/actions";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.sidebar.loading);

  const loginUser = (email, password) => {
    dispatch(setLoading(true));
    Auth.Login(email, password)
      .then((res) => {
        toast.success(res.data.msg);
        dispatch(setUserLogin(res.data));
        navigate(`/`);
      })
      .catch((err) => {
        toast.error("Incorrect email or password");
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const renderLoginWithEmail = () => {
    return (
      <Formik
        initialValues={{
          Email: "",
          Password: "",
        }}
        onSubmit={async (values) => {
          const { Email, Password } = values;
          loginUser(Email, Password);
        }}
        validationSchema={yup.object().shape({
          Email: yup
            .string()
            .strict(false)
            .trim()
            .email()
            .required("Email is required"),
          Password: yup.string().required("Password is required").min(6),
        })}
      >
        {({ values, handleChange, handleSubmit, errors, touched }) => {
          return (
            <div className="bg-image">
              <h1>Log In to GS Services</h1>
              <div style={{ width: "55%" }}>
                <TextInput
                  placeholder={"Email Address"}
                  className={"text-input"}
                  value={values.Email}
                  onChange={handleChange("Email")}
                  error={errors.Email && touched.Email}
                  errorText={errors.Email}
                />
                <TextInput
                  type={"password"}
                  placeholder={"Password"}
                  className={"text-input"}
                  value={values.Password}
                  onChange={handleChange("Password")}
                  error={errors.Password && touched.Password}
                  errorText={errors.Password}
                />
              </div>
              <Button
                title={"Log In"}
                loading={loading}
                icon={"forward_arrow"}
                onClick={handleSubmit}
              />
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="logo-div">
          <img src={electric_highway} className={"logo-icon"} />
        </div>
        <div className="logo-div">
          <Icon icon={"network_logo"} size={90} />
          <h2 className="logo-text">Network</h2>
        </div>
        <div className="logo-div">
          <Icon icon={"career_logo"} size={90} />
          <h2 className="logo-text">Careers</h2>
        </div>
        <div className="logo-div">
          <Icon icon={"gs_logo"} size={90} />
          <h2 className="logo-text">GS</h2>
        </div>
      </div>
      <div className="right-side">{renderLoginWithEmail()}</div>
      <ToastContainer />
    </div>
  );
};

export default Login;
