import axios from "axios";

const URL = process.env.REACT_APP_URL || "";

export const Notification = {
  FetchNotifications: async (token, dates) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/notification`, {
      headers,
      params: {
        startDate: dates[0],
        endDate: dates[1],
      },
    });
  },
};
