import React from "react";
import { Rings } from "react-loader-spinner";
import "./styles.scss";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = ({ children, showSearchBar, onSearchChange, searchPlaceholder, bgColor, loaderColor, inputValue }) => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.sidebar.loading);
  const menuClicked = useSelector((state) => state.sidebar.menuClicked);
  return (
    <div>
      <Header
        onAppLogoClick={() => {
          navigate("/");
        }}
        showSearchBar={showSearchBar}
        onSearchChange={onSearchChange}
        searchPlaceholder={searchPlaceholder}
        inputValue={inputValue}
      />
      <div className="main-content">
        <div
          className="screen-content"
          data-bg-color={bgColor}
          data-is-sidebar-open={!!menuClicked}
        >
          {loading ? (
            <div className="loader">
              <Rings
                height="100"
                width="100"
                color={loaderColor?.length ? "#005150" : "white"}
                ariaLabel="loading"
              />
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
      <p className="bottom-text">
        Made somewhere deep within the Metaverse © GS Martech GRIDSERVE
        Digital Network Management | 2021 GRIDSERVE ©️ All rights reserved. |
        Version 2.1
      </p>
    </div>
  );
};

export default Container;
