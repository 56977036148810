const locationTypes = [
  {
    name: 'Electric Hub',
    marker: 'marker_dark_green'
  },
  {
    name: 'Electric Forecourt',
    marker: 'marker_light_green'
  },
  {
    name: 'Hybrid Solar Farm',
    marker: 'marker_light_green'
  },
  {
    name: 'Electric Super Hub',
    marker: 'marker_orange'
  },
  {
    name: "Undefined",
    marker: "Undefined"
  }
]

export default locationTypes
