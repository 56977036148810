import React from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Charging from "../../../assets/images/Charging.svg";
import Unknown from "../../../assets/images/Cross-icon.png";
import Icon from "../../../components/Icon";
import CharacterStatus from "../../../components/CharacterStatus";
import StatusButton from "../../../components/StatusButton";
import EditDropdown from "../EditDropdown";
import Button from "../../../components/Button";

const ChargingStationsList = ({
  ind,
  evseData,
  activeEvDropdown,
  uid,
  locationName,
  createdAt,
  updatedAt,
  status,
  connectors,
  showDropdown,
  setShowDropdown,
  onChange,
  onDropdownSelect,
  userRole
}) => {
  const checkRole = () => {
    return userRole === 'MEMBER';
  }
  return (
    <div className="table-div">
      <table className="charging-stations-table">
        <>
          <thead className="center-header">
            <tr>
              <th className="flex-row">
                <p className="station-id">{uid}</p>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <div className="connectors">
                  <div className="connector-status-wrapper">
                    {connectors.map((connector) => {
                      return (
                        <>
                          {connector.status !== "CHARGING" ? (
                            connector.status === "AVAILABLE" ? (
                              <div
                                className="status available"
                                data-tip
                                data-for={connector.uid}
                              ></div>
                            ) : (
                              <img
                                src={Unknown}
                                class="status"
                                data-tip
                                data-for={connector.uid}
                              />
                            )
                          ) : (
                            <img
                              src={Charging}
                              data-tip
                              data-for={connector.uid}
                            />
                          )}
                          <ReactTooltip
                            id={connector.uid}
                            place="top"
                            effect="solid"
                          >
                            {connector.uid}
                          </ReactTooltip>
                        </>
                      );
                    })}
                  </div>
                </div>
              </th>
              <th className="icon-left">
                <Icon icon="vertical_dots" size={15} cursor={"pointer"} />
              </th>
            </tr>
          </thead>
        </>
        <thead className="bottom-header">
          <tr>
            <th>Connector</th>

            <th>Added Date</th>
            <th>Last Updated</th>
            <th>Max Power</th>
            <th>Phase Type</th>
            <th>Status</th>
            <th className="action-left">Actions</th>
          </tr>
        </thead>

        <tbody className="table-body">
          {connectors.map((item, index) => {
            return (
              <>
                <tr
                  className="table-row"
                  data-connector-status={item.status === "DELETED"}
                  onClick={() => {
                    if (item.status !== "DELETED") {
                      setShowDropdown(index)
                    }
                  }}
                >
                  <td>
                    <div className="flex-row">
                      <Icon
                        size={25}
                        icon={
                          item.type === "CHADEMO"
                            ? "chademo_black"
                            : item.type === "CCS"
                              ? "ccs_black"
                              : "type2_black"
                        }
                      />
                      <div style={{ width: "10px" }} />
                      {item.type === "CHADEMO"
                        ? "CHAdeMO"
                        : item.type === "CCS"
                          ? item.type
                          : "Type 2"}
                    </div>
                  </td>

                  <td>{createdAt ? moment(item.createdAt).fromNow() : null}</td>
                  <td>{updatedAt ? moment(item.updatedAt).fromNow() : null}</td>
                  <td>{item.max_electric_power}</td>
                  <td>{item.power_type}</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <CharacterStatus
                        title={item?.status?.toLowerCase()}
                        status={item?.status?.toLowerCase()}
                      />
                    </div>
                  </td>
                  <td
                    class={`${item.status === "DELETED" ? "restore-td" : ""}`}
                  >
                    <div className="flex-row">
                      <Icon icon="vertical_line" size={30} id={"i"} />
                      {item.status === "DELETED" ? (
                        <Button
                          title={"Restore"}
                          disabled={checkRole()}
                          onClick={() => {
                            onDropdownSelect(
                              "OUTOFORDER",
                              0,
                              "status",
                              ind,
                              index
                            );
                          }}
                        />
                      ) : (
                        <>
                          {!checkRole() && <div className="flex-row">
                            <Icon
                              cursor={"pointer"}
                              onClick={() => {
                                onDropdownSelect(
                                  "DELETED",
                                  0,
                                  "status",
                                  ind,
                                  index
                                );
                              }}
                              icon={"delete_black"}
                              size={25}
                            />
                          </div>
                          }
                          <Icon
                            icon="vertical_dots_black"
                            size={15}
                            onClick={() => setShowDropdown(index)}
                            cursor={"pointer"}
                          />
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                {showDropdown === index && activeEvDropdown === ind && (
                  <tr>
                    <td colspan="7">
                      <EditDropdown
                        item={evseData}
                        connectorData={item}
                        disabled={checkRole()}
                        onChange={(text, fieldName) =>
                          onChange(text, fieldName, ind, index)
                        }
                        onDropdownSelect={(
                          item,
                          selectedValIndex,
                          fieldName
                        ) => {
                          onDropdownSelect(
                            item,
                            selectedValIndex,
                            fieldName,
                            ind,
                            index
                          );
                        }}
                      />
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChargingStationsList;
