import axios from "axios";

const URL = process.env.REACT_APP_URL || "";

export const Management = {
  FetchUsers: async (token, page, noOfRows, searchText) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/user/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText: searchText,
      },
    });
  },
  FetchTeams: async (token, page, noOfRows, searchText) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/team/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText: searchText,
      },
    });
  },
  AddTeam: async (token, data) => {
    const headers = { Authorization: token };
    return axios.post(
      `${URL}/api/v1/team`,

      data,
      { headers }
    );
  },
  DeleteTeam: async (token, id) => {
    const headers = { Authorization: token };
    return axios.delete(`${URL}/api/v1/team/${id}`, { headers });
  },
  UpdateTeam: async (token, data, id) => {
    const headers = { Authorization: token };
    return axios.put(`${URL}/api/v1/team/${id}`, data, { headers });
  },

  AddUser: async (token, data) => {
    const headers = { Authorization: token };
    return axios.post(`${URL}/api/v1/user`, data, { headers });
  },
  DeleteUser: async (token, id) => {
    const headers = { Authorization: token };
    return axios.delete(`${URL}/api/v1/user/${id}`, { headers });
  },
  UpdateUser: async (token, data, id) => {
    const headers = { Authorization: token };
    return axios.put(`${URL}/api/v1/user/${id}`, data, { headers });
  },
  UpdateProfile: async (token, data, id) => {
    const headers = { Authorization: token };
    return axios.put(`${URL}/api/v1/profile/${id}`, data, { headers });
  },
};
