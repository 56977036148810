import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import networkLogo from "../../assets/network-logo.svg";
import "./style.scss";
import {
  setMenuClicked,
  setResetMenuClicked,
  setSideMenu,
} from "../../redux/sidebar/actions";
import ProfileWidget from "../ProfileWidget";
import Icon from "../Icon";
import { Location } from "./../../services/Location";
import { setUserLogout } from "../../redux/auth/actions";
import EditProfileModal from "../EditProfileModal";
import { initialSidebarMenus, subMenus } from "../../mock";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // redux states
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);
  const sideMenu = useSelector((state) => state.sidebar.sideMenu);
  const menuClicked = useSelector((state) => state.sidebar.menuClicked);

  const [sidebarMenus, setSidebarMenus] = useState(initialSidebarMenus);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [locationCount, setLocationCount] = useState(null);

  const switchSideMenu = (menu, name) => {
    if (
      !!!menuClicked ||
      (!!menuClicked && subMenus[name].mainTitle !== sideMenu.mainTitle)
    ) {
      dispatch(setMenuClicked(name));
    } else {
      dispatch(setMenuClicked(false));
    }
    dispatch(setSideMenu(menu));
    navigate(menu.mainItems[0].route)
  };

  //setting location as initial side menu
  useEffect(() => {
    dispatch(setSideMenu(subMenus.locations));
  }, []);

  useEffect(() => {
    //  resetting sidebar if user press app-logo on top and go to network performance screen
    if (window.location.pathname.length === 1) {
      dispatch(setResetMenuClicked());
    }

    //  get locations count
    fetchLocationsCount();
  }, []);

  // getting locations count where location status not equal to Deleted
  const fetchLocationsCount = () => {
    Location.Fetch_Locations_Count(token).then((res) => {
      setLocationCount(res.data.totalCount.toString());
    });
  };

  return (
    <>
      <div
        id="sidebar"
        className="sidebar"
        data-is-sidebar-open={!!menuClicked}
      >
        <div className="first-section">
          <Icon
            icon="gs_logo"
            className="logo"
            onClick={() => navigate("/")}
            cursor="pointer"
          />
          <div className="menu-section" data-is-sidebar-open={!!menuClicked}>
            {sidebarMenus.map((menu, index) => {
              return index !== 2 || userData.role === "ADMIN" ? (
                <button
                  className={`menu-item`}
                  data-is-sidebar-open={!!menuClicked}
                  data-disabled={menu.disabled}
                  disabled={menu.disabled}
                  onClick={() => switchSideMenu(subMenus[menu.name], menu.name)}
                >
                  <Icon
                    cursor={"pointer"}
                    disabled={menu.disabled}
                    icon={menu.icon}
                  />
                  <Icon
                    className={"active-menu"}
                    active={menu.name === menuClicked}
                    onClick={() =>
                      switchSideMenu(subMenus[menu.name], menu.name)
                    }
                    cursor={"pointer"}
                    disabled={menu.disabled}
                    icon={"active_menu"}
                  />
                </button>
              ) : (
                <></>
              );
            })}
          </div>
        </div>
        <button
          className="logout-button"
          data-is-sidebar-open={!!menuClicked}
          onClick={() => dispatch(setUserLogout())}
        >
          <Icon cursor={"pointer"} icon={"logout"} />
        </button>
      </div>
      <>
        {!!menuClicked && (
          <div className="more-menu">
            <div className="more-menu-top">
              <div className="overview-div">
                <div
                  className="left-arrow-div"
                  onClick={() => dispatch(setMenuClicked(false))}
                >
                  <Icon
                    icon={"left_arrow_icon"}
                    className={"left_arrow_icon"}
                    cursor={"pointer"}
                  />
                </div>
                <h2>Overview</h2>
              </div>
              <h6>{sideMenu.mainTitle}</h6>
              <div className="divider"></div>
              <div className="inner-more-menu">
                {sideMenu.mainItems.map((menu) => (
                  <h3
                    onClick={() => navigate(menu.route)}
                    className={menu.route === pathname && "active"}
                  >
                    {menu.title}
                  </h3>
                ))}
              </div>
            </div>

            <div className="more-menu-bottom">
              {!!sideMenu?.tools && (
                <>
                  <h6>Tools</h6>
                  <div className="divider"></div>
                  {sideMenu.tools.map((tool) => (
                    <>
                      <button
                        data-disabled={tool.disabled}
                        className="more-tools"
                        onClick={() => navigate(tool.route)}
                        disabled={tool.disabled}
                      >
                        <h3 className={tool.route === pathname && "active"}>
                          {tool.title}
                        </h3>
                      </button>
                    </>
                  ))}
                </>
              )}
              <div className="top-section">
                {showEditProfileModal && (
                  <EditProfileModal
                    onCloseEditModal={() => setShowEditProfileModal(false)}
                  />
                )}
                <ProfileWidget
                  onOpenEditModal={() => setShowEditProfileModal(true)}
                  avatar={userData?.image_path}
                  firstName={userData?.firstName}
                  lastName={userData?.lastName}
                  email={userData?.email}
                  isSidebarOpen={true}
                />
              </div>
              <h5 onClick={() => dispatch(setUserLogout())}>Logout</h5>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Sidebar;
