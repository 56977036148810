import React from "react";
import TextInput from "./../../components/TextInput/index";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import "./style.scss";
import Button from "./../Button/index";
import FieldSet from "../FieldSet";
import { Location } from "../../services/Location";
import { ToastContainer, toast } from "react-toastify";
import { serviceErrorHandler } from "../../config/toastError";
import { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { Icons } from "../../constants/icons";
import { setLoading } from "../../redux/sidebar/actions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const AddMsaModal = ({ setAddModal,setUpdate,update }) => {
  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);

  const dispatch = useDispatch();

  const [darkLogo, setDarkLogo] = useState(0);
  const [lightLogo, setLightLogo] = useState(0);
  const [logoValidation, setLogoValidation] = useState(false);
  function closeModal() {
    setAddModal(false);
  }
  const addTeam = async (Name, lightLogo, darkLogo) => {
    let imagesArray = [];
    imagesArray.push(lightLogo);
    imagesArray.push(darkLogo);
    const formData = new FormData();
    formData.append("name", Name);
    formData.append("lightLogo", lightLogo);
    formData.append("darkLogo", darkLogo);
    dispatch(setLoading(true));
    Location.AddMsa(token, formData)
      .then((res) => {
        setUpdate(!update);
        setAddModal(false);
       
      })
      .catch((error) => {
        toast.error(serviceErrorHandler(error));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const setDarkLogoFunction = (file) => {
    setDarkLogo(file);
    setLogoValidation(false);
  };
  const setLightLogoFunction = (file) => {
    setLightLogo(file);
    setLogoValidation(false);
  };
  return (
    <Formik
      initialValues={{
        Name: "",
      }}
      onSubmit={async (values) => {
        const { Name } = values;
        if (darkLogo || lightLogo) {
          setLogoValidation(false);
          await addTeam(Name, lightLogo, darkLogo);
        } else {
          setLogoValidation(true);
        }
      }}
      validationSchema={yup.object().shape({
        Name: yup.string().required("Name is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="msa-modal">
                <div className="top-div center-file">
                  <img
                    src={
                      typeof lightLogo === "string" && lightLogo.length > 0
                        ? lightLogo
                        : lightLogo !== null && typeof lightLogo === "object"
                        ? URL.createObjectURL(lightLogo)
                        : Icons["upload_icon"]
                    }
                  />
                  <div className="upload-avatar">
                    <FieldSet
                      type={"file"}
                      legend={"Light Logo"}
                      onChange={(e) => setLightLogoFunction(e.target.files[0])}
                    />
                  </div>
                </div>
                {logoValidation && (
                  <p className=" darklogo-error">select a Logo</p>
                )}
                <div className="top-div ">
                  <img
                    src={
                      typeof darkLogo === "string" && darkLogo.length > 0
                        ? darkLogo
                        : darkLogo !== null && typeof darkLogo === "object"
                        ? URL.createObjectURL(darkLogo)
                        : Icons["upload_icon"]
                    }
                  />
                  <div className="upload-avatar">
                    <FieldSet
                      type={"file"}
                      legend={"Dark Logo"}
                      className={"text-input"}
                      onChange={(e) => setDarkLogoFunction(e.target.files[0])}
                    />
                    {logoValidation && <p className="error">select a Logo</p>}
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"Name"}
                  className={"text-input margin-top"}
                  value={values.Name}
                  onChange={handleChange("Name")}
                  error={errors.Name && touched.Name}
                  errorText={errors.Name}
                />
                <div className="action-buttons">
                  <Button title={"Back"} outline={true} onClick={closeModal} />
                  <Button
                    loading={loading}
                    title={"submit"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal>
            <ToastContainer />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddMsaModal;
