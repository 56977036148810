import React, { useEffect } from "react";
import DefaultImage from "../DefaultImage";
import "./styles.scss";
import Avatar from "../Avatar";
import Icon from "../Icon";

const ProfileWidget = ({
  avatar,
  firstName,
  lastName,
  email,
  isSidebarOpen,
  onOpenEditModal,
}) => {
  useEffect(() => {
    var widget = document.querySelector(".widget");

    widget.onclick = function () {
      this.style.borderColor = "white";
    };
  }, []);

  return (
    <div
      className="widget"
      data-is-sidebar-open={isSidebarOpen}
      onClick={onOpenEditModal}
    >
      {avatar?.length ? (
        <Avatar cursor={"pointer"} size={"sm"} avatar={avatar} />
      ) : (
        <DefaultImage firstName={firstName} lastName={lastName} size={true} />
      )}

      {isSidebarOpen && (
        <div className="user-info">
          <p>{firstName}</p>
          <p>{email}</p>
        </div>
      )}
      {isSidebarOpen && <Icon cursor={"pointer"} icon={"down_arrow"} />}
    </div>
  );
};

export default ProfileWidget;
