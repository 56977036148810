import React from "react";

import "./styles.scss";
import Icon from "../Icon";

/*
Enums:
Size ["lg", "md", "sm"]
*/

const Avatar = ({ avatar, alt, size, cursor }) => {
  return (
    <div data-size={size} className="avatar">
      <img src={avatar} />
    </div>
  );
};

export default Avatar;
