import React, { useState, useEffect, useCallback } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import moment from "moment";
import debounce from "lodash.debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import StatusButton from "../../components/StatusButton/index";
import Icon from "../../components/Icon/index";
import TablePagination from "../../components/TablePagination";
import { ChargingStation } from "../../services/ChargingStation";
import Charging from "../../assets/images/Charging.svg";
import Unknown from "../../assets/images/Cross-icon.png";
import ReactTooltip from "react-tooltip";
import { setLoading } from "../../redux/sidebar/actions";
import Container from "../../components/Container";

const topBars = {
  all: "All",
  available: "Available",
  charging: "Charging",
  investigation: "Requires Investigation",
};

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme(
  "solarized",
  {
    text: {
      primary: "white",
    },
    background: {
      default: "#013131",
    },

    divider: {
      default: "#002626",
    },
  },
  "dark"
);

const TopBar = ({ setStatus }) => {
  return (
    <div className="top-bar-div">
      {Object.keys(topBars).map((item, index) => {
        return (
          <div className="bar-div">
            <p
              onClick={() => {
                setStatus(item);
              }}
            >
              {topBars[item]}
            </p>
            <div className="bar-line" data-line-color={topBars[item]} />
          </div>
        );
      })}
    </div>
  );
};

const ColumnComponent = ({ title, subTitle }) => {
  return (
    <div className="column-component">
      <p className="column-title">{title}</p>
      <p className="column-sub-title">{subTitle}</p>
    </div>
  );
};

const ChargingStations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const queryParam = query.get("status");

  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [noOfRows, setNoOfRows] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [pending, setPending] = useState(false);
  const [from, setFrom] = useState(0);
  const [sort, setSort] = useState("");
  const [orderType, setOrderType] = useState("asc");
  const [currentData, setCurrentData] = useState([]);
  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [status, setStatus] = useState(!!topBars[queryParam] ? queryParam :"all");

  useEffect(() => {
    fetchChargingStations();
    if (!!status) {
      params.set("status", status);
      setSearchParams(params.toString());
    }
  }, [status]);

  useEffect(() => {
    setPending(true);
    fetchChargingStations().finally(() => {
      setPending(false);
    });
  }, [page, searchText, noOfRows]);
  useEffect(() => {
    if (sort.length > 0) {
      fetchChargingStations();
    }
  }, [sort, orderType]);
  useEffect(() => {
    fetchChargingStations();
  }, [status]);
  async function fetchChargingStations() {
    return ChargingStation.Fetch_Charging_Stations(
      token,
      page,
      noOfRows,
      searchText,
      sort,
      orderType,
      status
    )
      .then(({ data }) => {
        setCurrentData(data.data);
        setFilterTotalCount(data.totalCount);
        if (totalCount === 0) {
          setTotalCount(data.totalCount);
        }
        dispatch(setLoading(false));
        if (page > 1) {
          setFrom((page - 1) * noOfRows + 1);
        } else {
          setFrom(1);
        }
      })
      .catch((error) => console.log("error here", error.response));
  }
  useEffect(() => {
    setPending(true);
    fetchChargingStations().finally(() => {
      setPending(false);
    });
  }, [page, searchText, noOfRows]);
  useEffect(() => {
    if (sort.length > 0) {
      setPending(true);
      fetchChargingStations().finally(() => {
        setPending(false);
      });
    }
  }, [sort, orderType]);
  useEffect(() => {
    setPending(true);
    fetchChargingStations().finally(() => {
      setPending(false);
    });
  }, [status]);

  // set search text
  const setSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  // debounce function to wait for a little time after user stop writing and then run changeHandler function to search location
  const debouncedChangeHandler = useCallback(debounce(setSearchInput, 300), []);

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  const onChargerClick = (row) => {
    navigate(`/edit-location/${row.location.id}`, {
      state: {
        index: 2,
      },
    });
  };
  const columns = [
    {
      name: <ColumnComponent title={"Status"} />,
      selector: (row) => row?.connectors?.length,
      cell: (row) => {
        if (!row?.connectors?.length) return;
        const status = row.connectors[0].status;
        return (
          <StatusButton
            status={status?.toLowerCase()}
            title={status?.toLowerCase()}
          />
        );
      },
      grow: 1,
    },
    {
      name: <ColumnComponent title={"Charging Station"} />,
      selector: (row) => row?.evs_id,
      width: "16rem",
      grow: 1,
      sortable: true,
      sortField: "evs_id",
    },
    {
      name: <ColumnComponent title={"Location"} />,
      selector: (row) => row?.location?.name,
      grow: 2,
      sortable: true,
      sortField: "location.name",
    },
    {
      name: <ColumnComponent title={"City"} />,
      selector: (row) => row?.location?.city,
      grow: 1.2,
      sortable: true,
      sortField: "location.city",
    },
    {
      name: <ColumnComponent title={"Created"} />,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      grow: 1,
      sortable: true,
      sortField: "createdAt",
    },
    {
      name: <ColumnComponent title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format("DD/MM/YYYY"),
      sortable: true,
      sortField: "updatedAt",
    },
    {
      name: <ColumnComponent title={"Connectors"} />,
      cell: (row) => {
        let jsonObject = {};
        for (let i = 0; i < row.connectors.length; i++) {
          const newConnector = row.connectors[i];
          if (!jsonObject[newConnector.uid]) {
            jsonObject[newConnector.uid] = [newConnector];
          } else {
            jsonObject[newConnector.uid].push(newConnector);
          }
        }
        {
          return (
            <div className="connectors-container">
              {Object.keys(jsonObject).map((item, index) => {
                return (
                  <div className="connectors-icons">
                    {jsonObject[item].map((connector) => {
                      return (
                        <>
                          {connector.status !== "CHARGING" ? (
                            connector.status === "AVAILABLE" ? (
                              <div
                                className="status available"
                                data-tip
                                data-for={connector.uid}
                              ></div>
                            ) : (
                              <img
                                src={Unknown}
                                class="status"
                                data-tip
                                data-for={connector.uid}
                              />
                            )
                          ) : (
                            <img
                              src={Charging}
                              data-tip
                              data-for={connector.uid}
                            />
                          )}
                          <ReactTooltip
                            id={connector.uid}
                            place="top"
                            effect="solid"
                          >
                            {connector.uid}
                          </ReactTooltip>
                        </>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      name: <Icon icon={"vertical_dots"} />,
      cell: (row) => (
        <Icon
          cursor={"pointer"}
          icon={"vertical_dots"}
          onClick={() => onChargerClick(row)}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
    },
  ];

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div style={{ marginTop: "0.5rem" }}>
      <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
    </div>
  ));

  return (
    <Container showSearchBar onSearchChange={debouncedChangeHandler}>
      <div className="charging-stations-container">
        <ActionHeader
          appLogo={true}
          title={"Charging Stations"}
          showButtons
          leftButtonTitle="Create"
          leftButtonDisable={true}
          rightButtonTitle="GFX Sync"
          rightButtonDisable={true}
          totalCount={filterTotalCount}
          filterStatus={status}
        />

        <Divider />
        <TopBar setStatus={setStatus} />
        <div className="charging-station-table">
          <DataTable
            className="rdt_Table"
            columns={columns}
            data={currentData}
            progressPending={pending}
            onSort={(column, sortDirection) => {
              setSort(column.sortField);
              setOrderType(sortDirection);
            }}
            sortServer
            highlightOnHover={true}
            theme="solarized"
            onRowClicked={onChargerClick}
            responsive={true}
            pagination={true}
            paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
            selectableRowsComponent={Checkbox}
            paginationComponent={({ currentPage, onChangeRowsPerPage }) => {
              return totalCount > 0 ? (
                <TablePagination
                  page={page}
                  totalCount={totalCount}
                  itemsPerPage={noOfRows === "All" ? totalCount : noOfRows}
                  onHandlePageClick={(selectedPage) =>
                    onHandlePageClick(selectedPage)
                  }
                  from={from}
                  currentData={currentData}
                  setShowRowsDropdown={setShowRowsDropdown}
                  showRowsDropdown={showRowsDropdown}
                  noOfRows={noOfRows}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  setPage={setPage}
                  setNoOfRows={setNoOfRows}
                />
              ) : (
                <></>
              );
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default ChargingStations;
