import React from "react";
import TextInput from "./../../components/TextInput/index";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { serviceErrorHandler } from "../../config/toastError";
import * as yup from "yup";
import "./style.scss";
import Avatar from "./../Avatar/index";
import Button from "./../Button/index";
import ReactDropdown from "../ReactDropdown";
import FieldSet from "../FieldSet";
import { Management } from "../../services/Management";
import { useEffect, useState, useMemo } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Icons } from "../../constants/icons";
import { setLoading } from "../../redux/sidebar/actions";
import countryList from "react-select-country-list";
import Select from "react-select";

const roles = ["ADMIN", "USER", "MEMBER"];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const AddUserModal = ({
  onClickNo,
  onClickYes,
  setAddUserModal,
  onDropdownSelect,
}) => {
  const options = useMemo(() => countryList().getData(), []);
  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  const [page, setPage] = useState(1);
  const [file, setFile] = useState();
  const [noOfRows, setNoOfRows] = useState(50);
  const [team, setTeam] = useState("");
  const [userRole, setUserRole] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [teamError, setTeamError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();
  function closeModal() {
    setAddUserModal(false);
  }
  const addUser = async (
    firstName,
    lastName,
    Email,
    Password,
    Country,
    Team,
    Role
  ) => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", Email);
    formData.append("password", Password);
    formData.append("teamId", Team);
    formData.append("country", Country);
    formData.append("role", Role);
    if (file) {
      formData.append("image_path", file);
    }

    dispatch(setLoading(true));
    Management.AddUser(token, formData)
      .then((res) => {
        setAddUserModal(false);
      })
      .catch((error) => toast.error(serviceErrorHandler(error)))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  const selectedTeam = (item) => {
    setTeam(item.value);
  };
  const selectedRole = (item) => {
    setUserRole(item.value);
  };
  const selectCountry = (item) => {
    setCountry(item);
  };

  useEffect(() => {
    Management.FetchTeams(token, page, noOfRows, searchText)
      .then((res) => {
        setTeamData(res.data.data);
      })
      .catch((error) => console.log("error", error.response));
  }, []);

  return (
    <Formik
      initialValues={{
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        Team: "",
        ConfirmPassword: "",
      }}
      onSubmit={async (values) => {
        const { FirstName, LastName, Email, Password } = values;
        if (team && userRole && country) {
          setCountryError(false);
          setTeamError(false);
          await addUser(
            FirstName,
            LastName,
            Email,
            Password,
            country?.label,
            team,
            userRole
          );
        } else {
          setTeamError(true);
          setCountryError(true);
        }
      }}
      validationSchema={yup.object().shape({
        FirstName: yup.string().required("firstName is required"),
        LastName: yup.string().required("lastName is required"),
        Email: yup
          .string()
          .strict(false)
          .trim()
          .email()
          .required("Email is required"),
        Password: yup.string().required("Password is required").min(6),
        ConfirmPassword: yup
          .string()
          .oneOf([yup.ref("Password"), null], "Passwords must match")
          .required("Confirm password is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="edit-modal">
                <div className="top-div">
                  <img
                    src={
                      typeof file === "string" && file.length > 0
                        ? file
                        : file !== null && typeof file === "object"
                        ? URL.createObjectURL(file)
                        : Icons["upload_icon"]
                    }
                  />
                  <div className="upload-avatar">
                    <TextInput
                      type={"file"}
                      className={"text-input"}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <p className="recommend-text">
                      Recommended dimensions: 200x200, maximum file size: 5MB
                    </p>
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"First name"}
                  className={"text-input margin-top"}
                  value={values.FirstName}
                  onChange={handleChange("FirstName")}
                  error={errors.FirstName && touched.FirstName}
                  errorText={errors.FirstName}
                />
                <FieldSet
                  type={"text"}
                  legend={"Last name"}
                  className={"text-input margin-top"}
                  value={values.LastName}
                  onChange={handleChange("LastName")}
                  error={errors.LastName && touched.LastName}
                  errorText={errors.LastName}
                />
                <FieldSet
                  type={"text"}
                  legend={"Email Address"}
                  className={"text-input margin-top"}
                  value={values.Email}
                  onChange={handleChange("Email")}
                  error={errors.Email && touched.Email}
                  errorText={errors.Email}
                />
                <FieldSet
                  type={showPassword ? "text" : "password"}
                  legend={"Password"}
                  className={"text-input margin-top"}
                  value={values.Password}
                  onChange={handleChange("Password")}
                  error={errors.Password && touched.Password}
                  errorText={errors.Password}
                  Password={true}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
                <FieldSet
                  type={showConfirmPassword ? "text" : "password"}
                  legend={"Confirm Password"}
                  className={"text-input margin-top"}
                  value={values.ConfirmPassword}
                  onChange={handleChange("ConfirmPassword")}
                  error={errors.ConfirmPassword && touched.ConfirmPassword}
                  errorText={errors.ConfirmPassword}
                  Password={true}
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                />
                <Dropdown
                  options={
                    teamData?.rows?.length > 0
                      ? teamData.rows.map((row) => {
                          return { value: row?.id, label: row?.name };
                        })
                      : []
                  }
                  placeholder="Select Team "
                  onChange={selectedTeam}
                />
                {teamError ? <p className="error">Select team</p> : ""}

                <div className="country">
                  <Select
                    options={options}
                    value={country}
                    onChange={selectCountry}
                    placeholder="Select Country"
                  />
                  {countryError ? <p className="error">Select country</p> : ""}
                </div>

                <Dropdown
                  options={roles}
                  placeholder="Select Role"
                  onChange={selectedRole}
                />
                {teamError ? <p className="error">Select role</p> : ""}
                <div className="action-buttons">
                  <Button title={"Back"} outline={true} onClick={closeModal} />
                  <Button
                    loading={loading}
                    title={"submit"}
                    onClick={handleSubmit}
                    type="submit"
                  />
                </div>
              </div>
            </Modal>
            <ToastContainer />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddUserModal;
