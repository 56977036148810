import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EditProfileModal from "../EditProfileModal";
import ProfileWidget from "../ProfileWidget";
import Icon from "../Icon";
import SearchInput from "../SearchInput";
import { setMenuClicked } from "../../redux/sidebar/actions";
import "./styles.scss";

const Header = ({
  showSearchBar,
  onSearchChange,
  searchPlaceholder,
  inputValue,
}) => {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);

  const userData = useSelector((state) => state.auth.user);
  const menuClicked = useSelector((state) => state.sidebar.menuClicked);
  const sideMenu = useSelector((state) => state.sidebar.sideMenu);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    if (!!menuClicked) {
      dispatch(setMenuClicked(false));
    } else {
      dispatch(setMenuClicked(sideMenu.name));
    }
  };

  return (
    <header>
      <div className="sidebar-open-header">
        <div className="left-content" data-is-sidebar-open={menuClicked}>
          {!menuClicked && (
            <div onClick={toggleSidebar} className="openSidebar">
              <Icon
                icon={"arrow_right"}
                className={"sidebar-icon"}
                cursor={"pointer"}
              />
            </div>
          )}
        </div>
        {showSearchBar && (
          <SearchInput
            onChange={onSearchChange}
            placeholder={searchPlaceholder}
            inputValue={inputValue}
          />
        )}
        <div className="right-content" data-is-sidebar-open={menuClicked}>
          <div className="top-section">
            {showEditProfileModal && (
              <EditProfileModal
                onCloseEditModal={() => setShowEditProfileModal(false)}
              />
            )}
            <ProfileWidget
              onOpenEditModal={() => setShowEditProfileModal(true)}
              avatar={userData?.image_path}
              firstName={userData?.firstName}
              lastName={userData?.lastName}
              email={userData?.email}
              isSidebarOpen={false}
            />
          </div>
          <div
            className="notificationDiv"
            onClick={() => {
              navigate("/feed-notifications");
            }}
          >
            <Icon icon={"notification"} cursor={"pointer"} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
