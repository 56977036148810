import React, { useEffect } from "react";

import TextInput from "../../../components/TextInput";
import ReactDropdown from "../../../components/ReactDropdown";
import { Icons } from "../../../constants/icons";
import { Location } from "./../../../services/Location";
import locationTypes from "../../../constants/locationTypes";

const AdditionalComponent = ({
  name,
  locationId,
  locationAddress,
  msaData,
  locationType,
  locationTypeStatus,
  cost,
  city,
  msa,
  Image,
  postalCode,
  countryCode,
  gmbLink,
  locationFirendlyUrl,
  locationSummary,
  showPayment,
  showFacilities,
  showReview,
  locationStatus,
  onChange,
  onDropdownSelect,
  userRole
}) => {
  const checkRole = () =>{
    return userRole==="MEMBER";
  }
  return (
    <div className="additional">
      <div className="left-content">
        <div className="inputs-div">
          <div className="input-and-label">
            <p className="label">Name</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={name}
                onChange={(e) => onChange(e.target.value, "locationName")}
                disabled={checkRole()}
              />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">Location ID</p>
            <div style={{ width: "100%" }}>
              <TextInput disabled={true} value={locationId}  />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">Address</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={locationAddress}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "locationAddress")}
              />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">Main Phone</p>
            <div style={{ width: "100%" }}>
              <TextInput disabled={true} />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">Featured Message</p>
            <div style={{ width: "100%" }}>
              <TextInput disabled={true} />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">
              Location Identifier
              <br />/ Slug
            </p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={locationFirendlyUrl}
                disabled={checkRole()}
                onChange={(e) =>
                  onChange(e.target.value, "locationFriendlyUrl")
                }
              />
            </div>
          </div>

          <div className="input-and-label" data-flex-start="true">
            <p className="label">Image</p>
            <div className="upload-btn-wrapper">
              <img
                className={`location-image ${
                  Image === null ||
                  (typeof Image === "string" && Image.length === 0)
                    ? "null-image"
                    : ""
                }`}
                htmlFor="actual-btn"
                src={
                  typeof Image === "string" && Image.length > 0
                    ? Image
                    : Image !== null && typeof Image === "object"
                    ? URL.createObjectURL(Image)
                    : Icons["upload_icon"]
                }
              />
              <input
                type="file"
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.files[0], "image_path")}
                className="upload-image-btn"
              />
            </div>
          </div>

          <div className="input-and-label">
            <p className="label">Type</p>
            <ReactDropdown
                disabled={checkRole()}
              dropdownValue={
                locationType ? locationTypes[+locationType].name : ""
              }
              dropdownData={[
                { value: 0, label: "Electric Hub" },
                { value: 1, label: "Electric Forecourt" },
                { value: 3, label: "Electric Super Hub" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "locationType")
              }
            />
          </div>

          <div className="input-and-label">
            <p className="label">Location Status</p>
            <ReactDropdown
                disabled={checkRole()}
              dropdownValue={locationTypeStatus ? locationTypeStatus : ""}
              dropdownData={[
                { value: "in construction", label: "in construction" },
                { value: "in planning", label: "in planning" },
                { value: "live", label: "live" },
                { value: "out of service", label: "out of service" },
                { value: "site removed", label: "site removed" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "locationTypeStatus")
              }
            />
          </div>

          <div className="input-and-label">
            <p className="label">Cost</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={cost}
                disabled={true}
                onChange={(e) => onChange(e.target.value, "cost")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="right-content">
        <div className="inputs-div">
          <div className="input-and-label">
            <p className="label">City</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={city}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "city")}
              />
            </div>
          </div>
          <div className="input-and-label">
            <p className="label">Post Code</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={postalCode}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "postalCode")}
              />
            </div>
          </div>
          <div className="input-and-label">
            <p className="label">Country Code</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={countryCode}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "countryCode")}
              />
            </div>
          </div>
          <div className="input-and-label">
            <p className="label">GMB Link</p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={gmbLink}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "gmb")}
              />
            </div>
          </div>
          <div className="input-and-label">
            <p className="label">
              Brief Location
              <br />
              Summary
            </p>
            <div style={{ width: "100%" }}>
              <TextInput
                value={locationSummary}
                disabled={checkRole()}
                onChange={(e) => onChange(e.target.value, "locationSummary")}
              />
            </div>
          </div>
          <div className="input-and-label">
            <p className="label">Show Payment</p>
            <ReactDropdown
              disabled={true}
              dropdownValue={showPayment ? showPayment : ""}
              dropdownData={[
                { value: 0, label: "Yes" },
                { value: 1, label: "No" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "showPayment")
              }
            />
          </div>
          <div className="input-and-label" data-flex-start="true">
            <p className="label">MSA</p>
            <ReactDropdown
              dropdownValue={msa ? msa.name : ""}
              disabled={checkRole()}
              dropdownData={msaData?.map((singleMSA) => {
                return { value: singleMSA?.id, label: singleMSA?.name };
              })}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "msaId")
              }
            />
          </div>
          <div className="input-and-label">
            <p className="label">Show facilities</p>
            <ReactDropdown
              dropdownValue={showFacilities ? showFacilities : ""}
              disabled={checkRole()}
              dropdownData={[
                { value: 0, label: "Yes" },
                { value: 1, label: "No" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "showFacilities")
              }
            />
          </div>
          <div className="input-and-label">
            <p className="label">Show Review</p>
            <ReactDropdown
              disabled={true}
              dropdownValue={showReview ? showReview : ""}
              dropdownData={[
                { value: 0, label: "Yes" },
                { value: 1, label: "No" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "showReview")
              }
            />
          </div>
          <div className="input-and-label">
            <p className="label">Moderation status</p>
            <ReactDropdown
              dropdownValue={locationStatus ? locationStatus : ""}
              disabled={checkRole()}
              dropdownData={[
                { value: 0, label: "Pending" },
                { value: 1, label: "Approved" },
                { value: 2, label: "Rejected" },
                { value: 3, label: "Deleted" },
                { value: 4, label: "Hidden" },
              ]}
              onDropdownSelect={(item, index) =>
                onDropdownSelect(item, index, "status")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalComponent;
