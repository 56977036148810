import React from "react";
import ReactDropdown from "../../../components/ReactDropdown";

import "./styles.scss";
import TextInput from "../../../components/TextInput";

const EditDropdown = ({ item, connectorData, onChange, onDropdownSelect, disabled=false }) => {
  return (
    <div className="edit-dropdown">
      <div className="left-section">
        <p className="input-label">UID</p>
        <TextInput
          className="edit-input"
          value={connectorData.uid}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value, "uid")}
        />
        <p className="input-label">Evse Id</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.evse_id}
          onChange={(e) => onChange(e.target.value, "evseId")}
        />
        <p className="input-label">Status</p>
        <ReactDropdown
          isChargingStation={true}
          disabled={disabled}
          className="dropdown-react"
          dropdownData={[
            { value: 0, label: "AVAILABLE" },
            { value: 1, label: "BLOCKED" },
            { value: 2, label: "CHARGING" },
            { value: 3, label: "INOPERATIVE" },
            { value: 4, label: "OUTOFORDER" },
            { value: 5, label: "PLANNED" },
            { value: 6, label: "REMOVED" },
            { value: 7, label: "RESERVED" },
            { value: 8, label: "UNKNOWN" },
          ]}
          dropdownValue={connectorData?.status}
          onDropdownSelect={(item, index) =>
            onDropdownSelect(item, index, "status")
          }
        />
        <p className="input-label">Type</p>
        <ReactDropdown
          className="dropdown-react"
          disabled={disabled}
          dropdownValue={connectorData?.type}
          dropdownData={[
            { value: 0, label: "CHAdeMO" },
            { value: 1, label: "Type2" },
            { value: 2, label: "CCS" },
          ]}
          onDropdownSelect={(item, index) =>
            onDropdownSelect(item, index, "type")
          }
        />
        <p className="input-label">Format</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.format}
          onChange={(e) => onChange(e.target.value, "format")}
        />
      </div>
      <div className="right-section">
        <p className="input-label">Standard</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.standard}
          onChange={(e) => onChange(e.target.value, "standard")}
        />
        <p className="input-label">Power Type</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.power_type}
          onChange={(e) => onChange(e.target.value, "powerType")}
        />
        <p className="input-label">Voltage</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.voltage}
          onChange={(e) => onChange(e.target.value, "voltage")}
        />
        <p className="input-label">Amperage</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.amperage}
          onChange={(e) => onChange(e.target.value, "amperage")}
        />
        <p className="input-label">Max Electric Power</p>
        <TextInput
          className="edit-input"
          disabled={disabled}
          value={connectorData.max_electric_power}
          onChange={(e) => onChange(e.target.value, "maxElectric")}
        />
      </div>
    </div>
  );
};

export default EditDropdown;
