import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Page from "../../components/Page";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import "./styles.scss";
import NotificationTypes from "./NotificationTypes";
import { Notification } from "./../../services/Notification";
import { setLoading } from "../../redux/sidebar/actions";
import Container from "../../components/Container";

const topBars = ["Pending", "Accepted", "Rejected"];

const TopBar = () => {
  return (
    <div className="top-bar-div">
      {topBars.map((item, index) => {
        return (
          <div className="bar-div">
            <p>{item}</p>
            <div className="bar-line" data-line-color={item} />
          </div>
        );
      })}
    </div>
  );
};

const FeedNotifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  useEffect(() => {
    Notification.FetchNotifications(token, dates)
      .then((res) => {
        setNotifications(res.data.data.rows);
      })
      .catch((error) => console.log("error", error))
      .finally(() => dispatch(setLoading(false)));
  }, [dates]);

  return (
    <Container showSearchBar>
      <div className="notifications-container">
        <ActionHeader
          setDates={setDates}
          dates={dates}
          title={"Live Feed Notifications"}
          showCalendar
        />
        <Divider />
        <TopBar />
        <div className="main-div">
          <div className="titles-div">
            <h4 style={{ paddingLeft: "3rem" }}>Notification</h4>
            <h4>Actions</h4>
          </div>
          <div className="notifications-div">
            {notifications.map((item, index) => {
              return <NotificationTypes item={item} index={index} />;
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FeedNotifications;
