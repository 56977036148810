import React from "react";

import "./styles.scss";

/*
Enums:
Status ["live", "pending", "in construction","in planning","out of service","site removed"]
*/

const LocationStatus = ({status }) => {
  const allStatus = {
    "live": "Live",
    "pending": "Pending",
    "in construction": "Construction",
    "in planning": "Planning",
    "out of service": "Out of service",
    "site removed": "Site Removed",

  }
  return (
    <>
      {status?.length > 0 &&
        <div className="status-tag" data-status={status}>
          <p>{allStatus[status]}</p>
        </div>
      }
    </>
  );
};

export default LocationStatus;
