import React, { useEffect, useState, useMemo } from "react";
import TextInput from "./../../components/TextInput/index";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";
import "../AddUserModal/style.scss";
import Avatar from "./../Avatar/index";
import Button from "./../Button/index";
import ReactDropdown from "../ReactDropdown";
import FieldSet from "../FieldSet";
import { Management } from "../../services/Management";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Icons } from "../../constants/icons";
import { setLoading } from "../../redux/sidebar/actions";
import { setUserData } from "../../redux/auth/actions";
import countryList from "react-select-country-list";
import Select from "react-select";
import DefaultImage from "../DefaultImage"
import { clearStorage } from "mapbox-gl";

const roles = ["ADMIN", "USER", "MEMBER"];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const EditUserModal = ({
  onClickNo,
  userData,
  setEditUserModal,
  onDropdownSelect,
}) => {
  const options = useMemo(() => countryList().getData(), []);
  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  const [page, setPage] = useState(1);
  const [file, setFile] = useState();
  const [noOfRows, setNoOfRows] = useState(50);
  const [team, setTeam] = useState(userData?.teamId ? userData.teamId : 0);
  const [userRole, setUserRole] = useState(userData?.role);
  const [countryError, setCountryError] = useState(false);
  const [country, setCountry] = useState("");
  const [searchText, setSearchText] = useState("");
  // const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState([]);

  const userDataId = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  function closeModal() {
    setEditUserModal(false);
  }
  const updateUser = async (FirstName, LastName, Email, Country) => {
    const formData = new FormData();
    formData.append("firstName", FirstName);
    formData.append("lastName", LastName);
    formData.append("email", Email);
    formData.append("teamId", team);
    formData.append("country", Country);
    formData.append("role", userRole);
    formData.append("image_path", file);
    formData.append("oldImage", userData?.image_path ? userData?.image_path:"");

    dispatch(setLoading(true));
    Management.UpdateUser(token, formData, userData?.id)
      .then((res) => {
        setEditUserModal(false);
        if (userDataId.id === userData.id) {
          dispatch(setUserData({ user: { ...res.data.user } }));
        }
      })
      .catch((error) => console.log("error", error.response))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  const selectedTeam = (item) => {
    setTeam(item.value);
  };
  const selectedRole = (item) => {
    setUserRole(item.value);
  };
  const selectCountry = (item) => {
    setCountry(item);
  };

  useEffect(() => {
    Management.FetchTeams(token, page, noOfRows, searchText)
      .then((res) => {
        setTeamData(res.data.data);
      })
      .catch((error) => console.log("error", error.response));
    setCountry(options.find((item) => item.label === userData.country));
  }, []);

  return (
    <Formik
      initialValues={{
        FirstName: userData.firstName,
        LastName: userData.lastName,
        Email: userData.email,
      }}
      onSubmit={async (values) => {
        const { FirstName, LastName, Email } = values;
        if (country) {
          setCountryError(false);
          await updateUser(FirstName, LastName, Email, country?.label);
        } else {
          setCountryError(true);
        }
      }}
      validationSchema={yup.object().shape({
        FirstName: yup.string().required("firstName is required"),
        LastName: yup.string().required("lastName is required"),
        Email: yup
          .string()
          .strict(false)
          .trim()
          .email()
          .required("Email is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="edit-modal">
              <div className="top-div">
                  {userData?.image_path?.length ||
                  typeof imagePath === "object" ? (
                    <img
                      src={
                        userData?.image_path?.length   
                          ? userData?.image_path
                          : typeof file === "string" &&
                            file.length > 0
                          ? file
                          : file !== null && typeof file === "object"
                          ? URL.createObjectURL(file)
                          : Icons["upload_icon"]
                      }
                    />
                  ) : (
                    <DefaultImage
                      firstName={userData?.firstName}
                      lastName={userData?.lastName}
                      size={false}
                    />
                  )}
                  <div className="upload-avatar">
                    <TextInput
                      type={"file"}
                      className={"text-input"}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <p className="recommend-text">
                      Recommended dimensions: 200x200, maximum file size: 5MB
                    </p>
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"First Name"}
                  className={"text-input margin-top"}
                  value={values.FirstName}
                  defaultValue={userData?.firstName}
                  onChange={handleChange("FirstName")}
                  error={errors.FirstName && touched.FirstName}
                  errorText={errors.FirstName}
                />
                <FieldSet
                  type={"text"}
                  legend={"Last Name"}
                  className={"text-input margin-top"}
                  value={values.LastName}
                  defaultValue={userData?.lastName}
                  onChange={handleChange("LastName")}
                  error={errors.LastName && touched.LastName}
                  errorText={errors.LastName}
                />

                <FieldSet
                  type={"text"}
                  legend={"Email Address"}
                  className={"text-input margin-top"}
                  value={values.Email}
                  defaultValue={userData?.email}
                  onChange={handleChange("Email")}
                  error={errors.Email && touched.Email}
                  errorText={errors.Email}
                />

                {teamData?.rows?.length && (
                  <Dropdown
                    options={teamData?.rows?.map((row) => {
                      return { value: row?.id, label: row?.name };
                    })}
                    placeholder="Select Team "
                    onChange={selectedTeam}
                    value={
                      userData?.team?.id
                        ? {
                            value: userData?.team?.id,
                            label: userData?.team?.name,
                          }
                        : "Select Team"
                    }
                  />
                )}
                <div className="country">
                  <Select
                    options={options}
                    value={country}
                    onChange={selectCountry}
                    placeholder="Select Country"
                  />
                  {countryError ? <p className="error">Select country</p> : ""}
                </div>
                <Dropdown
                  options={roles}
                  placeholder="Select Role"
                  onChange={selectedRole}
                  value={userData?.role}
                />
                <div className="action-buttons">
                  <Button title={"Back"} outline={true} onClick={closeModal} />
                  <Button
                    loading={loading}
                    title={"save"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

export default EditUserModal;
