import "./styles.scss";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { PieChart } from "react-minimal-pie-chart";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon";
import { VictoryPie } from "victory";

const ProgressBarComponent = ({ label, value, bgColor, completed }) => {
  return (
    <div className="progress-bar">
      <div className="progress-label">
        <p>{label}</p>
        <p>({value}%)</p>
      </div>
      <ProgressBar
        completed={completed}
        height={"9px"}
        borderRadius={"99px"}
        isLabelVisible={false}
        baseBgColor={"white"}
        bgColor={bgColor}
        width={"100%"}
        animateOnRender={true}
      />
    </div>
  );
};

const PerformanceCard = ({ title, progressData, count, id, tooltipText }) => {
  let progressLabel = {};
  let pieLabel = {};
  return (
    <div className="performance-card">
      <div className="blur-image"></div>
      <div className="bg-img">
        <div className="top-header">
          <Icon icon="menu" />
          <h6>{title}</h6>
          <div className="header-right-icons">
            <Icon icon="refresh" />
            {tooltipText && (
              <div data-tip data-for={`registerTip${id}`}>
                <Icon icon="info" />
              </div>
            )}
          </div>
        </div>
        <div className="progress-div">
          <div className="left-content">
            {progressData?.map((tempBar, index) => {
              let bar = {...tempBar};
              if (bar.title === 'Live' || bar.title === 'Available') {
                if (!progressLabel[bar.title]) {
                  progressLabel[bar.title] = bar;
                  bar= {...tempBar, value: tempBar.value*2};
                }
                else return <></>;
              }
              return (
                <>
                  <ProgressBarComponent
                    label={bar.title}
                    value={((bar?.value / count?.totalCount) * 100).toFixed(1)}
                    bgColor={bar.color}
                    completed={((bar?.value / count?.totalCount) * 100).toFixed(
                      1
                    )}
                  />
                </>
              );
            })}
          </div>
          <div className="right-content">
            <div className="innerWrapperDiv">
              {/* <VictoryPie
              data={progressData.data}
              colorScale={progressData.colors}
              innerRadius={75}
              cornerRadius={15}
              labelRadius={95}
              labels={({ datum }) => datum.y}
              style={{ labels: { fill: "white", fontSize: 20, fontWeight: "bold", fontFamily: 'Poppins' } }}
              /> */}
              <PieChart
                rounded={true}
                animationDuration={800}
                lengthAngle={-360}
                lineWidth={50}
                animate={true}
                data={progressData}
                label={({ dataEntry }) => {
                  // let tempDataEntry = {...dataEntry};
                  // if (tempDataEntry.title === 'Live' || tempDataEntry.title === 'Available') {
                  //   if (!pieLabel[tempDataEntry.title]) {
                  //     pieLabel[tempDataEntry.title] = tempDataEntry;
                  //     tempDataEntry= {...tempDataEntry, value: tempDataEntry.value*2};
                  //   }
                  //   else return '';
                  // }
                  // console.log({tempDataEntry})
                  // return tempDataEntry.value > 0 ?  tempDataEntry.value : ''
                  return ''
                }}
                labelPosition={100 - 50 / 2}
                labelStyle={{
                  fill: '#fff',
                  fontSize: '6px',
                  fontWeight: 'bold',
                  pointerEvents: 'none',
                }}
              />
              {count?.totalCount &&
                <p className="locations-number">{count?.totalCount}</p>
              }
            </div>
          </div>
        </div>
      </div>{" "}
      {id && (
        <ReactTooltip id={`registerTip${id}`} place="left" effect="solid">
          This chart shows the status of individual EVSEs.<br /><br />
          Offline EVSEs and EVSEs, whose charge stations <br />
          are not in production, are included in the chart.
        </ReactTooltip>
      )}
    </div>
  );
};

export default PerformanceCard;
