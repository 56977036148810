import profile from "../assets/profile.svg";
import down_arrow from "../assets/down-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";
import menu from "../assets/menu.svg";
import menu_item from "../assets/menu-item.svg";
import logout from "../assets/logout.svg";
import logo from "../assets/logo.svg";
import notification from "../assets/notification.svg";
import sidebar_icon from "../assets/sidebar-icon.svg";
import search from "../assets/search.svg";
import filter from "../assets/filter.svg";
import data from "../assets/data.svg";
import analytics from "../assets/analytics.svg";
import live_feed from "../assets/live-feed.svg";
import marketing from "../assets/marketing.svg";
import integrations from "../assets/integrations.svg";
import charger from "../assets/charger.svg";
import refresh from "../assets/refresh.svg";
import info from "../assets/info.svg";
import marketing_circle from "../assets/marketing-circle.svg";
import notification_circle from "../assets/notification-circle.svg";
import map_circle from "../assets/map-circle.svg";
import charger_circle from "../assets/charging-circle.svg";
import up_arrow_small from "../assets/up-arrow-small.svg";
import down_arrow_small from "../assets/down-arrow-small.svg";
import tick from "../assets/tick.png";
import vertical_dots from "../assets/vertical-dots.svg";
import location_marker from "../assets/location-marker.svg";
import thunder from "../assets/thunder.svg";
import cross_orange from "../assets/cross-orange.svg";
import tick_green from "../assets/tick-green.svg";
import upload_image from "../assets/upload-image.svg";
import google_business from "../assets/google-business.svg";
import app_logo from "../assets/app-logo.svg";
import gfsx from "../assets/gfxs.svg";
import ccs from "../assets/ccs.svg";
import chademo from "../assets/chademo1.svg";
import up_arrow from "../assets/up-arrow.svg";
import reload from "../assets/reload.svg";
import ccs_black from "../assets/ccs-black.svg";
import arrow_down from "../assets/arrow-down.svg";
import chademo_black from "../assets/chademo-black.svg";
import vertical_dots_black from "../assets/vertical-dots-black.svg";
import charging_locations from "../assets/charging-locations.svg";
import charging_stations from "../assets/charging-stations.svg";
import dashboard_light from "../assets/dahsboard-light.svg";
import chart_light from "../assets/chart-light.svg";
import settings from "../assets/settings.svg";
import setting_light from "../assets/setting-light.svg";
import vertical_line from "../assets/vertical-line.svg";
import play_button from "../assets/play-button.png";
import unlocked from "../assets/unlocked.png";
import arrow_down_short from "../assets/arrow-down-short.png";
import marker_light_green from "../assets/type-marker-light-green.png";
import marker_dark_green from "../assets/type-marker-dark-green.png";
import marker_orange from "../assets/type-marker-orange-green.png";
import dustbin from "../assets/dustbin.svg";
import delete_black from "../assets/delete-black.svg";
import eye_off from "../assets/eye-off.svg";
import eye_open from "../assets/eye-open.svg";
import type2_black from "../assets/type2_black.png";
import management from "../assets/management.svg";
import extra from "../assets/images/extra.png";
import gridserve from "../assets/images/gridserve-logo.png";
import ikea from "../assets/images/ikea.png";
import mollie from "../assets/images/mollie.png";
import moto from "../assets/images/moto.png";
import active_menu from "../assets/active_menu.svg";
import network_logo from "../assets/network-logo.svg";
import career_logo from "../assets/career-logo.svg";
import gs_logo from "../assets/gs-logo.svg";
import forward_arrow from "../assets/forward-arrow.svg";
import profile1 from "../assets/profile1.svg";
import profile2 from "../assets/profile2.svg";
import profile3 from "../assets/profile3.svg";
import profile4 from "../assets/profile4.svg";
import upload_icon from "../assets/upload_icon.svg";
import left_arrow_icon from "../assets/left_arrow_icon.svg";
import arrow_right from "../assets/arrow_right.svg";

export const Icons = {
  profile,
  menu,
  down_arrow,
  right_arrow,
  menu_item,
  logout,
  logo,
  gfsx,
  notification,
  sidebar_icon,
  search,
  filter,
  data,
  analytics,
  live_feed,
  marketing,
  management,
  integrations,
  charger,
  refresh,
  info,
  marketing_circle,
  notification_circle,
  map_circle,
  charger_circle,
  up_arrow_small,
  down_arrow_small,
  tick,
  vertical_dots,
  location_marker,
  thunder,
  cross_orange,
  tick_green,
  upload_image,
  google_business,
  app_logo,
  ccs,
  chademo,
  up_arrow,
  reload,
  ccs_black,
  arrow_down,
  chademo_black,
  vertical_dots_black,
  charging_locations,
  charging_stations,
  dashboard_light,
  chart_light,
  settings,
  setting_light,
  vertical_line,
  play_button,
  unlocked,
  arrow_down_short,
  marker_light_green,
  marker_dark_green,
  marker_orange,
  dustbin,
  type2_black,
  delete_black,
  network_logo,
  career_logo,
  gs_logo,
  forward_arrow,
  profile1,
  profile2,
  profile3,
  profile4,
  upload_icon,
  left_arrow_icon,
  arrow_right,
  active_menu,
  eye_off,
  eye_open,
};
