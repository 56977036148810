import React from "react";
import "./styles.scss";

import TextInput from "./../TextInput/index";
import ReactDropdown from "./../ReactDropdown/index";

const FieldSet = ({
  legend,
  dropdownData,
  value,
  dropdownValue,
  disabled,
  type,
  onChange,
  error,
  errorText,
  className,
  Password,
  showPassword,
  setShowPassword,
}) => {
  return (
    <fieldset data-is-disabled={disabled}>
      <legend>{legend}</legend>
      {!dropdownData?.length ? (
        <TextInput
          value={value}
          type={type}
          onChange={onChange}
          error={error}
          errorText={errorText}
          className={className}
          Password={Password}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      ) : (
        <ReactDropdown
          className="dropdown-react"
          dropdownData={dropdownData}
          dropdownValue={dropdownValue}
        />
      )}
    </fieldset>
  );
};

export default FieldSet;
