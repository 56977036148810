import React from "react";

import { Icons } from "../../constants/icons";

const Icon = ({ icon, alt, onClick, className, size, cursor,visibility,disabled, active }) => {
  return (
    <img
      style={{
        objectFit:'contain',
        height: `${size}px`,
        width: `${size}px`,
        cursor: cursor &&  !disabled ? cursor : "default",
        visibility: visibility ? 'hidden' : 'visible',
        filter: disabled && 'invert(76%) sepia(13%) saturate(20%) hue-rotate(16deg) brightness(89%) contrast(85%)' 
      }}
      data-disabled={disabled}
      className={`${className}`}
      src={Icons[icon]}
      alt={alt}
      onClick={() => (onClick ? onClick() : null)}
      data-active={active}
    />
  );
};

export default Icon;
