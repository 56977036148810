import axios from "axios";
import { clearStorage } from "mapbox-gl";

const URL = process.env.REACT_APP_URL || '';

export const Location = {
  Fetch_Locations_Count: async (token) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/locations-count`, { headers });
  },
  Fetch_Locations_Stats: async (token) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/locations-stats`, { headers });
  },

  Fetch_Locations: async (
    token,
    page,
    noOfRows,
    searchText,
    sort,
    orderType
  ) => {
    const headers = { Authorization: token };

    return axios.get(`${URL}/api/v1/locations/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText,
        sort,
        orderType,
      },
    });
  },
  Fetch_Location_By_Id: async (token, id) => {
    const headers = { Authorization: token };
    return axios.get(`${URL}/api/v1/location/${id}`, { headers });
  },

  Location_Delete: async (token, locationId) => {
    const headers = { Authorization: token };
    return axios.delete(`${URL}/api/v1/location/${locationId}`, { headers });
  },
  Location_restore: async (token, locationId) => {
    const headers = { Authorization: token };
    return axios.put(`${URL}/api/v1/restore-location/${locationId}`, { headers });
  },
  Msa_Delete: async (token, msaId) => {
    const headers = { Authorization: token };
    return axios.delete(`${URL}/api/v1/msa/${msaId}`, { headers });
  },
  Fetch_Msa: async (token) => {
    const headers = { Authorization: token };

    return axios.get(`${URL}/api/v1/msa`, {
      headers,
    });
  },
  Fetch_All_Msa: async (token, page, noOfRows, searchText, sort, orderType) => {
    const headers = { Authorization: token };
        
    return axios.get(`${URL}/api/v1/msa/${page}/${noOfRows}`, {
      headers,
      params: {
        searchText,
        sort,
        orderType,
      },
    });
  },
  Update: async (token, data) => {
    const headers = { Authorization: token };
    return axios.post(`${URL}/api/v1/location`, data, { headers });
  },
  Msa_Update: async (token, data, id) => {
    const headers = { Authorization: token };
    return axios.put(`${URL}/api/v1/msa/${id}`, data, { headers });
  },

  AddMsa: async (token, data) => {
    const headers = { Authorization: token };
    return axios.post(`${URL}/api/v1/msa-create`, data, { headers });
  },
};
