import React, { useState } from "react";
import { Rings } from "react-loader-spinner";
import Icon from "../Icon";
import "./styles.scss";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileWidget from "../ProfileWidget";
import EditProfileModal from "../EditProfileModal";
import { setUserLogout } from "../../redux/auth/actions";
import { setMenuClicked } from "../../redux/sidebar/actions";

const Page = ({ children, bgColor, loaderColor }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuClicked = useSelector(state => state.sidebar.menuClicked)

  return (
    <div className="page">
      <div className="two-sidebars">
        <Sidebar />
      </div>
      <div className="page-right-div"
        onClick={() => {
          if (!!menuClicked && window.innerWidth <= 992) {
            dispatch(setMenuClicked(false));
          }
        }}>
        <>{children}</>
      </div>
    </div>
  );
};

export default Page;
