import { React, useState, useEffect } from "react";
import StatusButton from "../../../components/StatusButton";
import Charging from "../../../assets/images/Charging.svg";
import Unknown from "../../../assets/images/Cross-icon.png";
import ReactTooltip from "react-tooltip";
import MapView from "../../../components/MapView";

const DetailsComponent = ({
  status,
  locationName,
  lat,
  lng,
  locationAddress,
  locationId,
  friendlyUrl,
  // onManageDetailsClick,
  setActiveTabIndex,
  evses,
  type,
}) => {
  const [connectors, setConnectors] = useState({});

  useEffect(() => {
    let jsonObject = {};
    for (let i = 0; i < evses.length; i++) {
      for (let j = 0; j < evses[i].connectors.length; j++) {
        const newConnector = evses[i].connectors[j];
        if (!jsonObject[newConnector.uid]) {
          jsonObject[newConnector.uid] = [newConnector];
        } else {
          jsonObject[newConnector.uid].push(newConnector);
        }
      }
    }
    setConnectors(jsonObject);
  }, []);

  return (
    <>
      <div className="left-content">
        <p className="label">Status</p>
        <StatusButton title={status} status={status.toLowerCase()} />
        <p className="label">Location Name</p>
        <p className="val-text">{locationName}</p>
        <p className="label"> Full Address</p>
        <p className="val-text">{locationAddress}</p>
        <p className="label">Location ID</p>
        <p className="val-text">{locationId}</p>
        <p className="label">Friendly URL</p>
        <p className="val-text">
          {friendlyUrl ? friendlyUrl : "No link found"}
        </p>

        <div className="view-options">
          <p onClick={() => setActiveTabIndex(1)} className="manage-text">
            Manage details >
          </p>
          <p onClick={() => setActiveTabIndex(2)} className="manage-text">
            View Charging >
          </p>
        </div>
        <div className="all-connector-status">
          {Object.keys(connectors).map((item, index) => {
            return (
              <div className="connector-status-wrapper">
                {connectors[item].map((connector) => {
                  return (
                    <>
                      {connector.status !== "CHARGING" ? (
                        connector.status === "AVAILABLE" ? (
                          <div
                            className="status available"
                            data-tip
                            data-for={connector.uid}
                          ></div>
                        ) : (
                          <img
                            src={Unknown}
                            class="status"
                            data-tip
                            data-for={connector.uid}
                          />
                        )
                      ) : (
                        <img src={Charging} data-tip data-for={connector.uid} />
                      )}
                      <ReactTooltip
                        id={connector.uid}
                        place="top"
                        effect="solid"
                      >
                        {connector.uid}
                      </ReactTooltip>
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <div className="right-content">
        <div className="topLngLat">
          <div className="lngLatContainer">
            <p className="lngLat">Lng:</p>
            <p>{lng}</p>
          </div>
          <div className="lngLatContainer">
            <p className="lngLat">Lat:</p>
            <p>{lat}</p>
          </div>
        </div>
        <div className="map">
          <MapView
            lat={lat}
            lng={lng}
            data={[{ lat: lat, lng: lng, type: type }]}
          />
          {/* <iframe
            id="iframe"
            width="100%"
            height="100%"
            frameBorder="0"
            src="https://maps.google.com/maps?q=55.507667,-3.6977627&hl=es;z=14&amp;output=embed"
          /> */}
        </div>
      </div>
    </>
  );
};

export default DetailsComponent;
