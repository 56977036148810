import React, { useState } from "react";

import "./styles.scss";
import Icon from "../Icon";

const TextInput = ({
  placeholder,
  type,
  value,
  disabled,
  className,
  dropdownData,
  onChange,
  error,
  errorText,
  Password,
  showPassword,
  setShowPassword,
}) => {
  let inputWrapperStyle;

  if (className?.length) {
    inputWrapperStyle = `input-wrapper ${className}`;
  } else {
    inputWrapperStyle = "input-wrapper";
  }

  return (
    <>
      <div
        data-error={error !== undefined}
        className={inputWrapperStyle}
        data-disabled={disabled}
      >
        <input
          data-error={error !== undefined}
          disabled={dropdownData?.length || disabled}
          className="input"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          type={type?.length ? type : "text"}
        />
        {Password &&
          (showPassword ? (
            <Icon
              icon="eye_off"
              className="iconImage"
              onClick={() => {
                setShowPassword(false);
              }}
              cursor={"pointer"}
            />
          ) : (
            <Icon
              icon="eye_open"
              className="iconImage"
              onClick={() => {
                setShowPassword(true);
              }}
              cursor={"pointer"}
            />
          ))}
      </div>
      {error && <p className="error-text">{errorText}</p>}
    </>
  );
};

export default TextInput;
