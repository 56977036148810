import React from "react";
import Modal from "react-modal";

import "./styles.scss";
import Button from "../Button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor:'white'
  },
};

const ConfirmationModal = ({
  onClickNo,
  onClickYes,
  headingTitle,
}) => {


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4>{headingTitle}</h4>
        <div className="buttons-container">
          <Button title={"No"} onClick={onClickNo} />
          <Button title={"Yes"} onClick={onClickYes} />
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
