import axios from "axios";
const URL = process.env.REACT_APP_URL || "";

export const Auth = {
  Login: async (email, password) => {
    return axios.post(`${URL}/api/v1/login`, {
      email: email,
      password: password,
    });
  },
};
