import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AuthRoute from "./authRoute";
import DashboardRoute from "./dashboardRoute";

const AppRouter = () => {
  return (
    <Router>
      <AuthRoute />
      <DashboardRoute />
    </Router>
  );
};
export default AppRouter;
