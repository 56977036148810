import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtectedRoute = () => {
  const userData = useSelector((state) => state.auth.user);
  return userData.role === "ADMIN" ? <Outlet /> : <Navigate replace to={"/"} />;
};

export default AdminProtectedRoute;
