import AppRouter from "./router/index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toggle/style.css"

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux";


function App() {
  console.log('test commit')
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;
