import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon";
import Button from "../../../components/Button";
import "./styles.scss";
import moment from "moment";

// render notification types
const RenderNotifications = ({ item }) => {
  const newConnectors = JSON.parse(`[${item.connectors}]`)
  return (
    <div>
      <p className="notification-text">
        <>
          {item.location_status === "Updated" ? "Location Updated:" : " New Location"} {' '}
          <span className="color-text">{item?.location?.name}</span> {item?.location?.gfx_location_id ? 'with GUID ' : ''} <span className="color-text">{item?.location?.gfx_location_id}</span> is ready for moderation with:
          <p className="notification-connector-container">
          {newConnectors.map((connector, index) => {
            return connector.status==='Updated'?<p>(EVSE Update) Existing EVSE i.e. <span className="color-text">{connector.evse_id}*{connector.connectorid}</span> that we already have on our system</p>:<p>(New EVSE) New EVSE <span className="color-text">{connector.evse_id+'*'+connector.connectorid} </span> that we do not already have on our system</p>
          })}
          </p>
        </>
      </p>
    </div>
  );
};

const NotificationTypes = ({ item, index }) => {
  const navigate = useNavigate();
  const renderIcon = (item) => {
    switch (item.type) {
      case "connector-arrived":
        return "chademo";
      case "location-arrived":
      default:
        return "chademo";
    }
  };
  const moderate = () => {
    if (item.type === "location-arrived") {
      navigate(`/edit-location/${item.location.id}`);
    }
    if (item.type === "connector-arrived") {
      navigate(`/edit-location/${item.location.id}`, {
        state: {
          index: 2,
          connector: item.connector,
        },
      });
    }
  };
  return (
    <div className="notification-type">
      <div className="left-div">
        <Icon icon={"gfsx"} size={27} />
        <div className="notification-info-div">
          <RenderNotifications item={item} />
          <p className="date-text">{moment(item.createdAt).fromNow()}</p>
        </div>
      </div>
      <div className="right-div">
        <Button
          outline={true}
          title={"Moderate"}
          disabled={false}
          size={"sm"}
          onClick={moderate}
        />
      </div>
    </div>
  );
};

export default NotificationTypes;
