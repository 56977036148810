import { SET_MENU_CLICKED, RESET_MENU_CLICKED, SET_LOADING, SET_SIDE_MENU} from "./types";

export const setMenuClicked = (title) => {
  return {
    type: SET_MENU_CLICKED,
    payload: title,
  };
};
export const setSideMenu = (menu) => {
  return {
    type: SET_SIDE_MENU,
    payload: menu,
  };
};
export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};

export const setResetMenuClicked = () => {
  return {
    type: RESET_MENU_CLICKED
  }
}

