import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import "./styles.scss";
import ActionHeader from "../../components/ActionHeader";
import Page from "../../components/Page";
import Divider from "../../components/Divider";
import Icon from "./../../components/Icon/index";
import { Icons } from "../../constants/icons";
import AdditionalComponent from "./AdditionalComponent";
import ChargingStationsList from "./ChargingStationListComponent";
import DetailsComponent from "./DetailsComponent";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Location } from "../../services/Location";
import { setLoading } from "../../redux/sidebar/actions";
import locationTypes from "../../constants/locationTypes";
import Container from "../../components/Container";

const topTabs = ["Details", "Additional", "Charging Stations"];

const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const ChargingStation = ({ status, item, index }) => {
  return (
    <>
      <div className="station" data-status={status}>
        <div className="station-name">
          <Icon icon="location_marker" className="location-marker" />
          <p className="name">Charging Station {index + 1}</p>
        </div>
        <p className="station-id">{item.uid}</p>
        <div className="connectors">
          <p className="connector-text">Connectors</p>
          <Icon icon="thunder" className="connector-icon" />
          <Icon icon="cross_orange" className="connector-icon" />
          <Icon icon="tick_green" className="connector-icon" />
        </div>
        <div className="date">
          <p className="date-label">Last Updated:</p>
          <p className="date-text">{moment(item.updatedAt).format("LLL")}</p>
        </div>
        <div className="date">
          <p className="date-label">Created:</p>
          <p className="date-text">{moment(item.createdAt).format("LLL")}</p>
        </div>
      </div>
    </>
  );
};

const EditLocation = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const index = state?.index;
  const connectorId = state?.connector;

  // params
  const { location_id } = useParams();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.sidebar.loading);

  // const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [msaData, setMsaData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(index || 0);
  const [showDropdown, setShowDropdown] = useState(null);
  const [activeEvDropdown, setActiveEvDropdown] = useState(null);

  useEffect(() => {
    if (locationData) return;
    fetchLocation();
    fetchMsa();
    setShowConfirmationModal(false);
  }, [location_id]);

  const fetchLocation = () => {
    Location.Fetch_Location_By_Id(token, location_id)
      .then(({ data }) => {
        setLocationData(data.data);
      })
      .catch((error) => {
        console.log("error", error);
        navigate(-1);
      });
  };
  const fetchMsa = () => {
    Location.Fetch_Msa(token, location_id)
      .then(({ data }) => {
        setMsaData(data.data);
      })
      .catch((error) => {
        console.log("error", error);
        navigate(-1);
      });
  };

  const onSaveLocation = () => {
    dispatch(setLoading(true));
    const formData = new FormData();
    const keys = Object.keys(locationData);
    keys.forEach((key) => {
      if (key === "evses") {
        formData.append(key + "[]", JSON.stringify(locationData[key]));
      } else {
        formData.append(key, locationData[key]);
      }
    });
    Location.Update(token, formData)
      .then((res) => {
        setLocationData(null);
        fetchLocation();
        toast.success("Location updated successfully");
      })
      .catch((err) => {
        toast.error("There's some error while updating Location");
      })
      .finally(() => {
        dispatch(setLoading(false));
        setShowConfirmationModal(false);
      });
  };

  // setting additional details text inputs
  const onChangeAdditionalDetails = (text, fieldName) => {
    switch (fieldName) {
      case "locationName":
        setLocationData({ ...locationData, name: text });
        break;
      case "locationAddress":
        setLocationData({ ...locationData, address: text });
        break;
      case "cost":
        setLocationData({ ...locationData, cost: text });
        break;
      case "city":
        setLocationData({ ...locationData, city: text });
        break;
      case "postalCode":
        setLocationData({ ...locationData, postal_code: text });
        break;
      case "countryCode":
        setLocationData({ ...locationData, country_code: text });
        break;
      case "gmb":
        setLocationData({ ...locationData, gmb: text });
        break;
      case "locationSummary":
        setLocationData({ ...locationData, location_summary: text });
        break;
      // still have to confirm about this
      case "msaId":
        setLocationData({ ...locationData, msaId: text });
        break;
      case "locationFriendlyUrl":
        setLocationData({ ...locationData, location_friendly_url: text });
        break;
      case "image_path":
        setLocationData({ ...locationData, image_path: text });
        break;

      default:
    }
  };

  // setting additional details dropdown values
  const onDropdownAdditionalDetails = (item, index, fieldName) => {
    let lowerCaseVal;

    switch (fieldName) {
      case "locationType":
        const typeString = index.toString();
        setLocationData({ ...locationData, type: typeString });
        break;
      case "locationTypeStatus":
        setLocationData({ ...locationData, type_status: item });
        break;
      case "showPayment":
        // lowerCaseVal = item.toLowerCase();
        setLocationData({ ...locationData, show_payment: item });
        break;
      case "showFacilities":
        // lowerCaseVal = item.toLowerCase();
        setLocationData({ ...locationData, show_facilities: item });
        break;
      case "showReview":
        // lowerCaseVal = item.toLowerCase();
        setLocationData({ ...locationData, show_review: item });
        break;
      case "status":
        setLocationData({ ...locationData, status: item });
        break;
      case "msaId":
        setLocationData({ ...locationData, msaId: index });
        break;
      default:
    }
  };

  // setting charging station text inputs
  const onChangeChargingStations = (
    text,
    fieldName,
    evsIndex,
    connectorIndex
  ) => {
    switch (fieldName) {
      case "uid":
        locationData.evses[evsIndex].connectors[connectorIndex].uid = text;
        setLocationData({ ...locationData });
        break;
      case "evseId":
        locationData.evses[evsIndex].connectors[connectorIndex].evse_id = text;
        setLocationData({ ...locationData });
        break;
      case "format":
        locationData.evses[evsIndex].connectors[connectorIndex].format = text;
        setLocationData({ ...locationData });
        break;
      case "standard":
        locationData.evses[evsIndex].connectors[connectorIndex].standard = text;
        setLocationData({ ...locationData });
        break;
      case "powerType":
        locationData.evses[evsIndex].connectors[connectorIndex].power_type =
          text;
        setLocationData({ ...locationData });
        break;
      case "voltage":
        locationData.evses[evsIndex].connectors[connectorIndex].voltage = text;
        setLocationData({ ...locationData });
        break;
      case "amperage":
        locationData.evses[evsIndex].connectors[connectorIndex].amperage = text;
        setLocationData({ ...locationData });
        break;
      case "maxElectric":
        locationData.evses[evsIndex].connectors[
          connectorIndex
        ].max_electric_power = text;
        setLocationData({ ...locationData });
        break;
      default:
    }
  };

  // setting charging station dropdown values
  const onDropdownChargingStations = (
    item,
    selectedValIndex,
    fieldName,
    evsIndex,
    connectorIndex
  ) => {
    switch (fieldName) {
      case "status":
        locationData.evses[evsIndex].connectors[connectorIndex].status = item;
        setLocationData({ ...locationData });
        break;
      case "type":
        locationData.evses[evsIndex].connectors[connectorIndex].type = item;
        setLocationData({ ...locationData });
        break;
      default:
    }
  };

  return (
    <div>
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={() => setShowConfirmationModal(false)}
          headingTitle={"Are you sure you want to save this Location?"}
          onClickNo={() => {
            setShowConfirmationModal(false);
          }}
          onClickYes={onSaveLocation}
        />
      )}
      <Container>
        {locationData && (
          <div className="edit-location-container">
            <ActionHeader
              appLogo
              title={locationData.name}
              subTitle={locationTypes[+locationData?.type].name}
              showButtons
              leftButtonTitle={"Back"}
              leftButtonClick={() => navigate(-1)}
              leftButtonDisable={loading}
              rightButtonTitle={"Save"}
              rightButtonClick={() => setShowConfirmationModal(true)}
              rightButtonDisable={userData.role === "MEMBER" || loading}
            />
            <Divider />

            <div className="edit-main-div">
              <div className="top-tabs">
                {topTabs.map((item, index) => {
                  return (
                    <div className="tab" onClick={() => setActiveTabIndex(index)}>
                      <p data-active-tab={activeTabIndex === index}>{item}</p>
                    </div>
                  );
                })}
              </div>
              <div className="wrapper-div">
                {activeTabIndex === 0 || activeTabIndex === 1 ? (
                  <>
                    <div className="inner-div">
                      <p className="heading-text">
                        {activeTabIndex === 0 ? "Details" : "Additional Details"}
                      </p>
                      {activeTabIndex === 0 && (
                        <section className="details">
                          <DetailsComponent
                            status={locationData?.status}
                            locationName={locationData?.name}
                            locationAddress={locationData?.address}
                            locationId={locationData?.gfx_location_id}
                            friendlyUrl={locationData?.location_friendly_url}
                            lat={locationData?.lat}
                            lng={locationData?.lng}
                            // onManageDetailsClick={() => setActiveTabIndex(1)}
                            setActiveTabIndex={setActiveTabIndex}
                            evses={locationData?.evses}
                            type={locationData?.type}
                          />
                        </section>
                      )}
                      {activeTabIndex === 1 && (
                        <section className="additional-details">
                          <p className="desc">
                            These are basic core details of your location,visible
                            on the GSEH Map
                            <br />
                            and their Platforms
                          </p>
                          <AdditionalComponent
                            name={locationData.name}
                            locationId={locationData?.gfx_location_id}
                            locationAddress={locationData?.address}
                            locationType={locationData?.type}
                            locationTypeStatus={locationData?.type_status}
                            cost={locationData?.cost}
                            city={locationData?.city}
                            msaData={msaData}
                            msa={locationData?.msa}
                            postalCode={locationData?.postal_code}
                            countryCode={locationData?.country_code}
                            gmbLink={locationData?.gmb}
                            Image={locationData?.image_path}
                            userRole={userData.role}
                            locationFirendlyUrl={
                              locationData?.location_friendly_url
                            }
                            locationSummary={locationData?.location_summary}
                            showPayment={capitalizeFirstLetter(
                              locationData?.show_payment
                            )}
                            showFacilities={capitalizeFirstLetter(
                              locationData?.show_facilities
                            )}
                            showReview={capitalizeFirstLetter(
                              locationData?.show_review
                            )}
                            locationStatus={locationData?.status}
                            onChange={(text, fieldName) =>
                              onChangeAdditionalDetails(text, fieldName)
                            }
                            onDropdownSelect={onDropdownAdditionalDetails}
                          />
                        </section>
                      )}
                    </div>
                  </>
                ) : null}

                {activeTabIndex === 1 && (
                  <section className="my-business">
                    <div className="business-title" id="business-title">
                      <img
                        src={Icons["google_business"]}
                        alt=""
                        style={{ marginRight: "0.8em" }}
                      />
                      <p className="google-text">Google</p>
                      <p className="business-text">My Business</p>
                      <p className="integration-text">Integration Details</p>
                    </div>
                    <p className="info-text">
                      These are basic core details of your location, visible on
                      the GSEH Map and other Platforms
                    </p>
                  </section>
                )}

                {activeTabIndex === 2 && (
                  <section className="charging-stations-list">
                    {locationData?.evses.map((item, ind) => {
                      return (
                        <ChargingStationsList
                          ind={ind}
                          evseData={item}
                          activeEvDropdown={activeEvDropdown}
                          connectorId={connectorId}
                          uid={item.evs_uid}
                          locationName={locationData.name}
                          createdAt={item.createdAt}
                          updatedAt={item.updatedAt}
                          status={item.status}
                          connectors={item.connectors}
                          showDropdown={showDropdown}
                          userRole={userData.role}
                          setShowDropdown={(index) => {
                            if (index === showDropdown) {
                              setShowDropdown(null);
                              setActiveEvDropdown(null);
                            } else {
                              setShowDropdown(index);
                              setActiveEvDropdown(ind);
                            }
                          }}
                          onChange={(text, fieldName, evsIndex, connectorIndex) =>
                            onChangeChargingStations(
                              text,
                              fieldName,
                              evsIndex,
                              connectorIndex
                            )
                          }
                          onDropdownSelect={onDropdownChargingStations}
                        />
                      );
                    })}
                  </section>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
      <ToastContainer />
    </div>
  );
};

export default EditLocation;