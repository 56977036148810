import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Management } from "./../../services/Management";
import ActionHeader from "../../components/ActionHeader";
import Icon from "../../components/Icon/index";
import TablePagination from "../../components/TablePagination";
import AddTeamModal from "../../components/AddTeamModal";
import EditTeamModal from "../../components/EditTeamModal";
import Page from "../../components/Page";
import { setLoading } from "../../redux/sidebar/actions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import Container from "../../components/Container";
import DefaultImage from "../../components/DefaultImage";

const TeamManagement = () => {
  const csvLink = useRef();
  const dispatch = useDispatch();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);

  // const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [team, setTeamData] = useState({});
  const [from, setFrom] = useState(0);
  const [noOfRows, setNoOfRows] = useState(50);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [DeleteTeamModal, setDeleteTeamModal] = useState(false);

  useEffect(() => {
    getAllTeams();
  }, [page, searchText, noOfRows, addTeamModal, EditModal]);

  const getAllTeams = () => {
    Management.FetchTeams(token, page, noOfRows, searchText)
      .then((res) => {
        setCurrentData(res.data.data.rows);
        setTotalCount(res.data.data.count);
        if (page > 1) {
          setFrom((page - 1) * noOfRows + 1);
        } else {
          setFrom(1);
        }
      })
      .catch((error) => console.log("error", error.response))
      .finally(() => dispatch(setLoading(false)));
  };

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };
  const openModal = () => {
    setAddTeamModal(true);
  };
  const openEditModal = (row) => {
    setEditModal(true);
    setTeamData(row);
  };
  const deleteTeam = (row) => {
    setTeamData(row);
    setDeleteTeamModal(true);
  };
  const onDeleteTeam = () => {
    Management.DeleteTeam(token, team.id)
      .then((res) => {
        setDeleteTeamModal(false);
        toast.success("Team Deleted Successfully");
        getAllTeams();
      })
      .catch((err) => {
        setDeleteTeamModal(false);
        toast.error(err.response.data.msg);
      });
  };

  const columns = [
    {
      name: "Team Name",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row?.image_path?.length ? (
            <img
              src={row?.image_path}
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <DefaultImage
              firstName={row?.name.split(" ")[0]}
              lastName={row?.name.split(" ")[1]}
              size={false}
            />
          )}
          <p style={{ fontWeight: "600", marginLeft: "1.3rem" }}>{row.name}</p>
        </div>
      ),
      width: "18rem",
      selector: (row) => row.name,
    },
    {
      name: "Team Size",
      selector: (row) => row.userCount,
    },
    {
      name: "Date Added",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      cell: (row) => (
        <Icon
          cursor={row.status !== "Deleted" && "pointer"}
          onClick={() => {
            deleteTeam(row);
          }}
          icon={"delete_black"}
          size={20}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
    },
    {
      name: "",
      cell: (row) => (
        <Icon
          cursor={"pointer"}
          icon={"vertical_dots"}
          onClick={() => openEditModal(row)}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
    },
  ];

  return (
    <div>
      <Container bgColor={"light-dark"}>
        <div className="user-management-container">
          <CSVLink
            ref={csvLink}
            filename={"users-data.csv"}
            data={currentData}
          />
          <ActionHeader
            bgColor="light-dark"
            title={"Team Management"}
            showButtons
            leftButtonClick={() => csvLink.current.link.click()}
            rightButtonClick={() => openModal()}
            leftButtonTitle="Export"
            leftButtonDisable={false}
            rightButtonTitle="+ New Team"
            rightButtonDisable={false}
          />
          <div className="user-management-table">
            {addTeamModal && <AddTeamModal setAddTeamModal={setAddTeamModal} />}
            {EditModal && (
              <EditTeamModal setEditModal={setEditModal} team={team} />
            )}

            {DeleteTeamModal && (
              <ConfirmationModal
                showConfirmationModal={DeleteTeamModal}
                setShowConfirmationModal={() => setDeleteTeamModal(false)}
                headingTitle={"Are you sure you want to delete this Team?"}
                onClickNo={() => {
                  setDeleteTeamModal(false);
                }}
                onClickYes={() => onDeleteTeam()}
              />
            )}
            <DataTable
              className="rdt_Table"
              columns={columns}
              data={currentData}
              highlightOnHover={true}
              selectableRowsVisibleOnly
              responsive={true}
              pagination={true}
              paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
              paginationComponent={({ currentPage, onChangeRowsPerPage }) => {
                return totalCount > 0 ? (
                  <TablePagination
                    light={true}
                    page={page}
                    totalCount={totalCount}
                    itemsPerPage={noOfRows}
                    onHandlePageClick={(selectedPage) =>
                      onHandlePageClick(selectedPage)
                    }
                    from={from}
                    currentData={currentData}
                    setShowRowsDropdown={setShowRowsDropdown}
                    showRowsDropdown={showRowsDropdown}
                    noOfRows={noOfRows}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setPage={setPage}
                    setNoOfRows={setNoOfRows}
                  />
                ) : (
                  <></>
                );
              }}
            />
          </div>
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default TeamManagement;
