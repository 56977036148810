import { clearStorage } from "mapbox-gl";
import React, { useState, useEffect } from "react";
import "./styles.scss";
// import { useSelector } from "react-redux";

const DefaultImage = ({ firstName, lastName, size }) => {
  const nameInitials = (firstName, lastName) => {
 
    let initial = '';
    if (firstName?.length > 0) initial += firstName?.trim()[0];
    if (lastName?.length > 0) initial += lastName?.trim()[0];
    return initial;
  };
  return (
    <div
      className={`${size === true ? "default-img-div sizeBig" : "default-img-div sizeSmall"
        }`}
    >
      <div className="inner-div small">{nameInitials(firstName, lastName)?.toUpperCase()}</div>
    </div>
  );
};

export default DefaultImage;
