import React from "react";
import Icon from "../Icon";
import { Rings } from "react-loader-spinner";

import "./styles.scss";

/*
Enums:
outline: true,false
disabled: true,false
size: 'sm','default','lg'
*/

const Button = ({ title, outline, disabled, icon, loading, size, onClick, ...props }) => {
  return (
    <button
      onClick={() => (onClick ? onClick() : null)}
      className="button-div"
      disabled={disabled}
      data-outline={outline}
      data-disabled={disabled}
      {...props}
    >
      {loading ? (
        <Rings height="30" width="30" color="white" ariaLabel="loading" />
      ) : (
        <p data-size={size}>{title}</p>
      )}
      <Icon icon={icon} />
    </button>
  );
};

export default Button;
