import React from "react";
import Modal from "react-modal";
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { Management } from "../../services/Management";
import * as yup from "yup";
import "./styles.scss";
import Avatar from "./../Avatar/index";
import Button from "./../Button/index";
import FieldSet from "../FieldSet";
import Dropdown from "react-dropdown";
import { setUserData } from "../../redux/auth/actions";
import { setLoading } from "../../redux/sidebar/actions";
import { Icons } from "../../constants/icons";
import DefaultImage from "../DefaultImage";
import countryList from "react-select-country-list";
import Select from "react-select";
const roles = ["ADMIN", "USER", "MEMBER"];
const customStyles = {
  overlay: { zIndex: "9999" },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const EditProfileModal = ({ onClickNo, onClickYes, onCloseEditModal }) => {
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  // redux states
  const userData = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  const [userRole, setUserRole] = useState(userData.role);
  const [imagePath, setImagePath] = useState(0);
  const [countryError, setCountryError] = useState(false);
  const [country, setCountry] = useState("");

  const updateUser = async (firstName, lastName, Email, Country) => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", Email);
    formData.append("teamId", userData.team.id);
    formData.append("country", Country);
    formData.append("role", userRole);
    formData.append("image_path", imagePath);
    formData.append("oldImage", userData?.image_path ? userData?.image_path:"");

    dispatch(setLoading(true));
    Management.UpdateProfile(token, formData, userData?.id)
      .then((res) => {
        dispatch(setUserData({ user: { ...res.data.user } }));
        onCloseEditModal();
      })
      .catch((error) => console.log("error", error.response))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  function closeModal() {
    onCloseEditModal();
  }
  const selectedRole = (item) => {
    setUserRole(item.value);
  };
  const selectCountry = (item) => {
    setCountry(item);
  };

  useEffect(() => {
    setCountry(options.find((item) => item.label === userData.country));
  }, []);
  return (
    <Formik
      initialValues={{
        firstName: userData.firstName,
        lastName: userData.lastName,
        Email: userData.email,
      }}
      onSubmit={async (values) => {
        const { firstName, lastName, Email, Country } = values;
        await updateUser(firstName, lastName, Email, country?.label);
      }}
      validationSchema={yup.object().shape({
        firstName: yup.string().required("firstName is required"),
        lastName: yup.string().required("lastName is required"),
        Email: yup
          .string()
          .strict(false)
          .trim()
          .email()
          .required("Email is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="edit-modal">
                <div className="top-div center-file">
                  {userData?.image_path?.length ||
                    typeof imagePath === "object" ? (
                    <img
                      // src={userData?.image_path}
                      src={
                        userData?.image_path?.length
                          ? userData?.image_path
                          : typeof imagePath === "string" &&
                            imagePath.length > 0
                            ? imagePath
                            : imagePath !== null && typeof imagePath === "object"
                              ? URL.createObjectURL(imagePath)
                              : Icons["upload_icon"]
                      }
                    />
                  ) : (
                    <DefaultImage
                      firstName={userData?.firstName}
                      lastName={userData?.lastName}
                      size={false}
                    />
                  )}
                  <div className="upload-avatar">
                    <FieldSet
                      type={"file"}
                      legend={"image"}
                      onChange={(e) => setImagePath(e.target.files[0])}
                    />
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"First Name"}
                  className={"text-input margin-top"}
                  value={values.firstName}
                  defaultValue={userData.firstName}
                  onChange={handleChange("firstName")}
                  error={errors.firstName && touched.firstName}
                  errorText={errors.firstName}
                />
                <FieldSet
                  type={"text"}
                  legend={"Last Name"}
                  className={"text-input margin-top"}
                  value={values.lastName}
                  defaultValue={userData.lastName}
                  onChange={handleChange("lastName")}
                  error={errors.lastName && touched.lastName}
                  errorText={errors.lastName}
                />
                <FieldSet
                  type={"text"}
                  legend={"Email Address"}
                  className={"text-input margin-top"}
                  value={values.Email}
                  defaultValue={userData.email}
                  onChange={handleChange("Email")}
                  error={errors.Email && touched.Email}
                  errorText={errors.Email}
                />
                <div className="country">
                  <Select
                    options={options}
                    value={country}
                    onChange={selectCountry}
                    placeholder="Select Country"
                  />
                  {countryError ? <p className="error">Select country</p> : ""}
                </div>
                <Dropdown
                  options={roles}
                  disabled
                  placeholder="Select Role"
                  onChange={selectedRole}
                  value={userData.role}
                />
                <div className="action-buttons">
                  <Button
                    title={"Back"}
                    outline={true}
                    onClick={onCloseEditModal}
                  />
                  <Button
                    loading={loading}
                    title={"Save"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

export default EditProfileModal;
