import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Page from "../components/Page";


const ProtectedRoute = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);

  const location = useLocation();
  const { pathname } = location;

  const loaderColor = () => {
    if (pathname.includes('user-management') || pathname.includes('team-management') || pathname.includes('feed-notifications')) {
      return "#005150"
    }
    else return "";
  }
  const bgColor = () => {
    if (pathname.includes('user-management') || pathname.includes('team-management')) {
      return "light-dark"
    }
    else return "";
  }


  return isLoggedIn ?
    <Page bgColor={bgColor()} loaderColor={loaderColor()}><Outlet /></Page> : <Navigate replace to={"/login"} />;
};

export default ProtectedRoute;
