import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import Login from "./../pages/Login/index";

const AuthRoute = () => {


  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
};

export default AuthRoute;
