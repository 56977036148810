import React from "react";

import "./styles.scss";
import Icon from "../Icon";

const SearchInput = ({ onChange, placeholder, inputValue, filter = false }) => {
  return (
    <div className="input-div">
      <Icon icon={"search"} className="search-icon" />
      <input type="search" placeholder={placeholder ? placeholder : "Search"} onChange={onChange} value={inputValue}/>
      {filter &&
        <Icon icon={"filter"} />}
    </div>
  );
};

export default SearchInput;
