import React from "react";

import "./styles.scss";

/*
Enums:
Status ["active", "pending", "construction","planning","syncing","offline"]
*/

const CharacterStatus = ({ title, status }) => {
  return (
    <div className="button" data-status={status}>
      <p>{title}</p>
    </div>
  );
};

export default CharacterStatus;
