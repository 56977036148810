import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import ProtectedRoute from "./protectedRoute";
import NetworkPerformance from "../pages/NetworkPerformance";
import Locations from "../pages/Locations";
import Msa from "../pages/Msa";
import EditLocation from "../pages/EditLocation";
import ChargingStations from "../pages/ChargingStations";
import UserManagement from "../pages/UserManagement";
import TeamManagement from "./../pages/TeamManagement/index";
import FeedNotifications from "../pages/FeedNotifications";
import Apilog from "../pages/ApiLog";
import Page from "../components/Page";
import AdminProtectedRoute from "./adminProtectedRoute";

const DashboardRoute = () => {
  const [page, setPage] = useState(null);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route exact path="/" element={<NetworkPerformance />} />
        <Route exact path="/locations" element={<Locations />} />
        <Route path="/msa" element={<Msa />} />
        <Route
          exact
          path="/edit-location/:location_id"
          element={<EditLocation />}
        />
        <Route exact path="/charging-stations" element={<ChargingStations />} />
        <Route element={<AdminProtectedRoute />}>
          <Route exact path="/user-management" element={<UserManagement />} />
          <Route exact path="/team-management" element={<TeamManagement />} />
        </Route>
        <Route
          exact
          path="/feed-notifications"
          element={<FeedNotifications />}
        />
        <Route exact path="/api-log" element={<Apilog />} />
      </Route>
    </Routes>
  );
};

export default DashboardRoute;
