import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
const appEnvironment = process.env.REACT_APP_ENV || "";
const sentryDSN = process.env.REACT_APP_SENTRY_KEY || "";

Sentry.init({
  dsn: sentryDSN,

  replaysSessionSampleRate: 0.1,

  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,

  environment: appEnvironment,

  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
