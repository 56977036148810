import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Management } from "./../../services/Management";
import ActionHeader from "../../components/ActionHeader";
import Icon from "../../components/Icon/index";
import TablePagination from "../../components/TablePagination";
import AddUserModal from "../../components/AddUserModal";
import EditModal from "./../../components/EditUserModal/index";
import { setLoading } from "../../redux/sidebar/actions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import Container from "../../components/Container";
import DefaultImage from "../../components/DefaultImage";
const UserManagement = () => {
  const csvLink = useRef();
  const dispatch = useDispatch();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [noOfRows, setNoOfRows] = useState(50);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [from, setFrom] = useState(0);
  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [EditUserModal, setEditUserModal] = useState(false);
  const [DeleteUserModal, setDeleteUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getAllUsers();
  }, [user, page, searchText, noOfRows, EditUserModal, addUserModal]);

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };
  const getAllUsers = () => {
    Management.FetchUsers(token, page, noOfRows, searchText)
      .then((res) => {
        setCurrentData(res.data.data.rows);
        setTotalCount(res.data.data.count);
        if (page > 1) {
          setFrom((page - 1) * noOfRows + 1);
        } else {
          setFrom(1);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => dispatch(setLoading(false)));
  };

  const editUser = (row) => {
    setUserData(row);
    setEditUserModal(true);
  };
  const deleteUser = (row) => {
    setUserData(row);
    setDeleteUserModal(true);
  };

  const onDeleteUser = () => {
    Management.DeleteUser(token, userData.id)
      .then((res) => {
        setDeleteUserModal(false);
        toast.success("User Deleted Successfully");
        getAllUsers();
      })
      .catch((err) => {
        setDeleteUserModal(false);
        toast.error(err.response.data.msg);
      });
  };
  const columns = [
    {
      name: "First Name",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row?.image_path?.length ? (
            <img
              src={row?.image_path}
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <DefaultImage
              firstName={row?.firstName}
              lastName={row?.lastName}
              size={false}
            />
          )}
          <p style={{ fontWeight: "600", marginLeft: "1.3rem" }}>
            {row.firstName}
          </p>
        </div>
      ),
      width: "18rem",
      selector: (row) => row.name,
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
    },
    {
      name: "Team",
      selector: (row) => row?.team?.name,
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },

    {
      cell: (row) => (
        <Icon
          cursor={row.status !== "Deleted" && "pointer"}
          onClick={() => {
            deleteUser(row);
          }}
          icon={"delete_black"}
          size={20}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
    },
    {
      name: "",
      cell: (row) => (
        <Icon
          cursor={"pointer"}
          icon={"vertical_dots"}
          onClick={() => editUser(row)}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
    },
  ];

  return (
    <div>
      <Container bgColor={"light-dark"}>
        <div className="user-management-container">
          <CSVLink ref={csvLink} filename={"users-data.csv"} data={currentData} />
          <ActionHeader
            bgColor="light-dark"
            title={"User Management"}
            showButtons
            leftButtonClick={() => csvLink.current.link.click()}
            rightButtonClick={() => setAddUserModal(true)}
            leftButtonTitle="Export"
            leftButtonDisable={false}
            rightButtonTitle="+ New User"
            rightButtonDisable={false}
          />
          {addUserModal && (
            <AddUserModal setAddUserModal={setAddUserModal} userData={userData} />
          )}
          {EditUserModal && (
            <EditModal setEditUserModal={setEditUserModal} userData={userData} />
          )}
          {DeleteUserModal && (
            <ConfirmationModal
              showConfirmationModal={DeleteUserModal}
              setShowConfirmationModal={() => setDeleteUserModal(false)}
              headingTitle={"Are you sure you want to delete this User?"}
              onClickNo={() => {
                setDeleteUserModal(false);
              }}
              onClickYes={() => onDeleteUser()}
            />
          )}
          <div className="user-management-table">
            <DataTable
              className="rdt_Table"
              columns={columns}
              data={currentData}
              highlightOnHover={true}
              // selectableRows
              selectableRowsVisibleOnly
              responsive={true}
              pagination={true}
              paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
              //  selectableRowsComponent={Checkbox}
              paginationComponent={({ currentPage, onChangeRowsPerPage }) => {
                return totalCount > 0 ? (
                  <TablePagination
                    light={true}
                    page={page}
                    totalCount={totalCount}
                    itemsPerPage={noOfRows}
                    onHandlePageClick={(selectedPage) =>
                      onHandlePageClick(selectedPage)
                    }
                    from={from}
                    currentData={currentData}
                    setShowRowsDropdown={setShowRowsDropdown}
                    showRowsDropdown={showRowsDropdown}
                    noOfRows={noOfRows}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setPage={setPage}
                    setNoOfRows={setNoOfRows}
                  />
                ) : (
                  <></>
                );
              }}
            />
          </div>
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default UserManagement;
