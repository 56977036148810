import React from "react";
import TextInput from "./../../components/TextInput/index";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import "../AddUserModal/style.scss";
import Button from "./../Button/index";
import FieldSet from "../FieldSet";
import { Location } from "../../services/Location";
import { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { Icons } from "../../constants/icons";
import { setLoading } from "../../redux/sidebar/actions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const EditMsaModal = ({ setEditModal, msaData }) => {
  // redux states
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [darkLogo, setDarkLogo] = useState(0);
  const [lightLogo, setLightLogo] = useState(0);
  const loading = useSelector((state) => state.sidebar.loading);
  const [file, setFile] = useState();
  const [logoValidation, setLogoValidation] = useState(false);
  function closeModal() {
    setEditModal(false);
  }
  const editMsa = async (Name) => {
    let imagesArray = [];
    imagesArray.push(lightLogo);
    imagesArray.push(darkLogo);
    const formData = new FormData();
    formData.append("name", Name);
    formData.append("lightLogo", lightLogo);
    formData.append("darkLogo", darkLogo);
    dispatch(setLoading(true));
    Location.Msa_Update(token, formData, msaData.id)
      .then((res) => {
        setEditModal(false);
      })
      .catch((error) => console.log("error", error.response))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const setDarkLogoFunction = (file) => {
    setDarkLogo(file);
    setLogoValidation(false);
  };
  const setLightLogoFunction = (file) => {
    setLightLogo(file);
    setLogoValidation(false);
  };
  return (
    <Formik
      initialValues={{
        Name: msaData.name,
      }}
      onSubmit={async (values) => {
        const { Name } = values;

        await editMsa(Name, lightLogo, darkLogo);
      }}
      validationSchema={yup.object().shape({
        Name: yup.string().required("Name is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="edit-modal">
                <div className="top-div center-file">
                  <img
                    src={
                      msaData?.light_Logo?.length
                        ? msaData?.light_Logo
                        : typeof lightLogo === "string" && lightLogo.length > 0
                        ? lightLogo
                        : lightLogo !== null && typeof lightLogo === "object"
                        ? URL.createObjectURL(lightLogo)
                        : Icons["upload_icon"]
                    }
                  />
                  <div className="upload-avatar">
                    <FieldSet
                      type={"file"}
                      legend={"Light Logo"}
                      onChange={(e) => setLightLogoFunction(e.target.files[0])}
                    />
                  </div>
                </div>
                {logoValidation && (
                  <p className=" darklogo-error">select a Logo</p>
                )}
                <div className="top-div ">
                  <img
                    src={
                      msaData?.dark_Logo?.length
                        ? msaData?.dark_Logo
                        : typeof darkLogo === "string" && darkLogo.length > 0
                        ? darkLogo
                        : darkLogo !== null && typeof darkLogo === "object"
                        ? URL.createObjectURL(darkLogo)
                        : Icons["upload_icon"]
                    }
                  />
                  <div className="upload-avatar">
                    <FieldSet
                      type={"file"}
                      legend={"Dark Logo"}
                      className={"text-input"}
                      onChange={(e) => setDarkLogoFunction(e.target.files[0])}
                    />
                    {logoValidation && <p className="error">select a Logo</p>}
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"Name"}
                  className={"text-input margin-top"}
                  value={values.Name}
                  defaultValue={msaData.name}
                  onChange={handleChange("Name")}
                  error={errors.Name && touched.Name}
                  errorText={errors.Name}
                />
                <div className="action-buttons">
                  <Button title={"Back"} outline={true} onClick={closeModal} />
                  <Button
                    loading={loading}
                    title={"save"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

export default EditMsaModal;
