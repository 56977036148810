import "./styles.scss";

import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { clearStorage } from "mapbox-gl";

const TablePagination = ({
  page,
  itemsPerPage,
  totalCount,
  onHandlePageClick,
  from,
  currentData,
  setShowRowsDropdown,
  showRowsDropdown,
  noOfRows,
  onChangeRowsPerPage,
  setPage,
  setNoOfRows,
  light = false,
}) => {
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalCount / itemsPerPage)
  );

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / itemsPerPage));
  }, [itemsPerPage, totalCount]);


  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    onHandlePageClick(event.selected + 1);
  };

  return (
    <div
      className="pagination-div"
      style={{ visibility: itemsPerPage === "All" ? "hidden" : "visible" }}
    >
      <div className={`select-rows-dropdown ${light ? "light" : ""}`}>
        <p>{`showing ${from} to ${
          from + currentData?.length - 1
        } of ${totalCount}`}</p>
        <div
          className="rows-dropdown"
          onClick={() => setShowRowsDropdown(!showRowsDropdown)}
        >
          <p>{noOfRows}</p>
          <Icon icon={"down_arrow"} size={16} cursor={"pointer"} />
          {showRowsDropdown ? (
            <div className="select-rows">
              {["All", 10, 25, 50, 100].map((item, index) => {
                return (
                  <p
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      // doing this bcz onChangeRowsPerPage not accepting all string so instead we are passing total count
                      if (item === "All") {
                        onChangeRowsPerPage(totalCount);
                      } else {
                        onChangeRowsPerPage(item);
                      }
                      setPage(1);
                      setNoOfRows(item);
                      setShowRowsDropdown(false);
                      // dispatch(setLoading(true));
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <ReactPaginate
        nextLabel="Next"
        forcePage={page - 1}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="Prev"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeLinkClassName="active"
        disabledLinkClassName="disabled"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default TablePagination;
