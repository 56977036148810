import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducers
import sidebarReducer from "./sidebar/reducer";
import authReducer from "./auth/reducer";

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  auth: authReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ['auth'],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };
