import React from "react";
import TextInput from "./../../components/TextInput/index";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import "../AddUserModal/style.scss";
import Button from "./../Button/index";
import FieldSet from "../FieldSet";
import { Management } from "../../services/Management";
import { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { Icons } from "../../constants/icons";
import { setLoading } from "../../redux/sidebar/actions";
import DefaultImage from "../DefaultImage";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 65px 35px 25px",
  },
};

const AddTeamModal = ({ setEditModal, team }) => {
  // redux states
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  const [file, setFile] = useState();
  function closeModal() {
    setEditModal(false);
  }
  const updateTeam = async (Name, Country) => {
    const formData = new FormData();
    formData.append("name", Name);
    formData.append("country", Country);
    if (file) {
      formData.append("image_path", file);
    }
    formData.append("oldImage", team?.image_path ? team?.image_path : "");
    dispatch(setLoading(true));
    Management.UpdateTeam(token, formData, team.id)
      .then((res) => {
        setEditModal(false);
      })
      .catch((error) => console.log("error", error.response))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Formik
      initialValues={{
        Name: team.name,
        // image_path: "",
        Country: team.country,
      }}
      onSubmit={async (values) => {
        const { Name, Country } = values;

        await updateTeam(Name, Country);
      }}
      validationSchema={yup.object().shape({
        Name: yup.string().required("Name is required"),
        Country: yup.string().required("Country is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div>
            <Modal
              isOpen={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="edit-modal">
                <div className="top-div">
                {team?.image_path?.length ||
                  typeof file === "object" ? (
                    <img
                      src={
                        team?.image_path?.length   
                          ? team?.image_path
                          : typeof file === "string" &&
                            file.length > 0
                          ? file
                          : file !== null && typeof file === "object"
                          ? URL.createObjectURL(file)
                          : Icons["upload_icon"]
                      }
                    />
                  ) : (
                    <DefaultImage
                      firstName={team?.name}
                      lastName={""}
                      size={false}
                    />
                  )}
                  <div className="upload-avatar">
                    <TextInput
                      type={"file"}
                      className={"text-input"}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <p className="recommend-text">
                      Recommended dimensions: 200x200, maximum file size: 5MB
                    </p>
                  </div>
                </div>
                <FieldSet
                  type={"text"}
                  legend={"Name"}
                  className={"text-input margin-top"}
                  value={values.Name}
                  defaultValue={team.name}
                  onChange={handleChange("Name")}
                  error={errors.Name && touched.Name}
                  errorText={errors.Name}
                />
                <FieldSet
                  type={"text"}
                  legend={"Country"}
                  className={"text-input margin-top"}
                  value={values.Country}
                  defaultValue={team.country}
                  onChange={handleChange("Country")}
                  error={errors.Country && touched.Country}
                  errorText={errors.Country}
                />
                <div className="action-buttons">
                  <Button title={"Back"} outline={true} onClick={closeModal} />
                  <Button
                    loading={loading}
                    title={"submit"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddTeamModal;
