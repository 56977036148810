import "./style.scss";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoicGxpZCIsImEiOiJja294em1qeGswZmpnMm9rODFtZnNlMWQzIn0.woMFKRxzyeHTA9oKW79aSg";

function MapView({ lat, lng, data }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(9);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
      doubleClickZoom: true,
      maxBounds: [
        [-25.5458, 40.5332],
        [15.5726, 62.255],
      ],
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
  }, []);

  useEffect(() => {
    if (map.current) {
      map.current.flyTo({
        center: [lng, lat],
        zoom: zoom,
      });
    }
  }, [lat, lng]);

  useEffect(() => {
    if (map.current) {
      if (markers.length !== 0) {
        markers.forEach((item) => {
          item.remove();
        });
      }
      let newMakers = [];
      data.forEach((item, index) => {
        let el = document.createElement("div");
        el.className = "marker";
        el.setAttribute("type", item.type);
        const marker = new mapboxgl.Marker(el)
          .setLngLat([item.lng, item.lat])
          .addTo(map.current);
        newMakers.push(marker);
      });
      setMarkers(newMakers);
    }
  }, [data, map]);

  return (
    <div className="map-container">
      <div ref={mapContainer} className="map" />
    </div>
  );
}

export default MapView;
