import React, { useState, useEffect, useCallback } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import "../Locations/styles.scss";
import Page from "../../components/Page";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import StatusButton from "../../components/StatusButton/index";
import TablePagination from "../../components/TablePagination";
import Icon from "./../../components/Icon/index";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Location } from "../../services/Location";
import EditMsaModal from "../../components/EditMsaModal";
import AddMsaModal from "../../components/AddMsaModal";
import { setLoading } from "../../redux/sidebar/actions";
import Toggle from "react-toggle";
import "./styles.scss";
import Container from "../../components/Container";

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme(
  "solarized",
  {
    text: {
      primary: "white",
    },
    background: {
      default: "#013131",
    },

    divider: {
      default: "#002626",
    },
  },
  "dark"
);

const ColumnComponent = ({ title, subTitle }) => {
  return (
    <div className="column-component">
      <p className="column-title">{title}</p>
      <p className="column-sub-title">{subTitle}</p>
    </div>
  );
};

const Msa = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.sidebar.loading);
  const [EditModal, setEditModal] = useState(false);
  const [AddModal, setAddModal] = useState(false);
  const [msaData, setMsaData] = useState({});
  const [page, setPage] = useState(1);
  const [noOfRows, setNoOfRows] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = useState(false);
  const [sort, setSort] = useState("");
  const [orderType, setOrderType] = useState("asc");
  const [currentData, setCurrentData] = useState([]);

  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedMsaId, setSelectedMsaId] = useState("");
  const [update, setUpdate] = useState(false);
  const [tableSettingsOpen, setTableSettingsOpen] = useState(false);
  // useEffect(() => {}, [startRow]);
  useEffect(() => {
    setPending(true);
    fetchMSA().finally(() => {
      setPending(false);
    });
  }, [page, searchText, noOfRows, update]);
  useEffect(() => {
    if (sort.length > 0) {
      fetchMSA();
    }
  }, [sort, orderType]);
  const refreshTable = () => {
    setUpdate(!update);
    setTableSettingsOpen(false);
  };
  async function fetchMSA() {
    return Location.Fetch_All_Msa(
      token,
      page,
      noOfRows,
      searchText,
      sort,
      orderType
    )
      .then(({ data }) => {

        if (!data.data.length) {
          if (page !== 1) {
            setPage(page - 1)
          }
        }
        setTotalCount(data.totalRows);
        setCurrentData(data.data);
        dispatch(setLoading(false));
        if (page > 1) {
          setFrom((page - 1) * noOfRows + 1);
        } else {
          setFrom(1);
        }
      })
      .catch((error) => console.log("error here", error.response));
  }

  const onDeleteMsa = () => {
    Location.Msa_Delete(token, selectedMsaId)
      .then((res) => {
        setShowConfirmationModal(false);
        refreshTable();
        toast.success("Msa Deleted Successfully");
      })
      .catch((err) => {
        setShowConfirmationModal(false);
        toast.error(err.response.data.msg);
      });
  };

  // set search text
  const setSearchInput = (event) => {
    setSearchText(event.target.value);
  };

  // debounce function to wait for a little time after user stop writing and then run setSearchInput function to search location
  const debouncedChangeHandler = useCallback(debounce(setSearchInput, 300), []);

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };
  const openEditModal = (row) => {
    setEditModal(true);
    setMsaData(row);
  };
  const openAddModal = (row) => {
    setAddModal(true);
  };
  const columns = [
    {
      name: <ColumnComponent title={"Light Logo"} />,
      cell: (row) => <img src={row?.light_Logo} width={60} alt="light logo" />,
      selector: (row) => row?.light_Logo,
      sortable: true,
      sortField: "light_Logo",
    },
    {
      name: <ColumnComponent title={"Dark Logo"} />,
      cell: (row) => <img src={row?.dark_Logo} width={60} alt="dark logo" />,
      selector: (row) => row?.dark_Logo,
      sortable: true,
      sortField: "dark_Logo",
    },
    {
      name: <ColumnComponent title={"Name"} />,
      selector: (row) => row.name,
      grow: 1,
      // compact: true,
      sortable: true,
      sortField: "name",
    },
    {
      name: <ColumnComponent title={"Id"} />,
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: <ColumnComponent title={"Updated Date"} />,
      cell: (row) => moment(row.updatedAt).format("DD/MM/YYYY"),
      selector: (row) => row.updatedAt,
      sortable: true,
      sortField: "updatedAt",
    },
    {
      name: <ColumnComponent title={"Label visibility"} />,
      cell: (row) => (
        <Toggle
          defaultChecked={false}
          icons={false}
          disabled
          className="msa-label-visibilty"
          onChange={() => null}
        />
      ),
    },
    {
      name: <ColumnComponent title={"Assigned To"} />,
      selector: (row) => row?.AssignedTo,
      grow: 1,
    },
    {
      cell: (row) => (
        <Icon
          cursor={"pointer"}
          onClick={() => {
            if (row.status === "Deleted") return;
            setSelectedMsaId(row.id);
            setShowConfirmationModal(!showConfirmationModal);
          }}
          icon={"dustbin"}
          size={20}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
      omit: userData.role === "MEMBER",
    },
    {
      name: <Icon icon={"vertical_dots"} />,
      cell: (row) => (
        <Icon
          cursor={"pointer"}
          icon={"vertical_dots"}
          onClick={() => openEditModal(row)}
        />
      ),
      width: "4em",
      style: { paddingLeft: "1.52em" },
      omit: userData.role === "MEMBER",
    },
  ];

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div style={{ marginTop: "0.5rem" }}>
      <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
    </div>
  ));

  return (
    <div>
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={() => setShowConfirmationModal(false)}
          headingTitle={"Are you sure you want to delete this Msa?"}
          onClickNo={() => {
            setShowConfirmationModal(false);
          }}
          onClickYes={onDeleteMsa}
        />
      )}
      <Container showSearchBar onSearchChange={debouncedChangeHandler}>

        <div className="location-container">
          <ActionHeader
            title={"Msa"}
            showButtons
            leftButtonTitle="Create"
            leftButtonClick={openAddModal}
            rightButtonTitle="GFX Sync"
            leftButtonDisable={userData.role === 'MEMBER'}
            rightButtonDisable={true}
          />
          {EditModal && (
            <EditMsaModal setEditModal={setEditModal} msaData={msaData} />
          )}
          {AddModal && <AddMsaModal setAddModal={setAddModal} setUpdate={setUpdate} update={update} />}

          <Divider />
          <div className="table-div">
            <DataTable
              className="rdt_Table"
              columns={columns}
              data={currentData}
              progressPending={pending}
              onSort={(column, sortDirection) => {
                setSort(column.sortField);
                setOrderType(sortDirection);
              }}
              sortServer
              highlightOnHover={true}
              theme="solarized"
              // selectableRows
              selectableRowsVisibleOnly
              responsive={true}
              pagination={true}
              paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
              selectableRowsComponent={Checkbox}
              paginationComponent={({ currentPage, onChangeRowsPerPage }) => {
                return totalCount > 0 ? (
                  <TablePagination
                    page={page}
                    totalCount={totalCount}
                    itemsPerPage={noOfRows}
                    onHandlePageClick={(selectedPage) =>
                      onHandlePageClick(selectedPage)
                    }
                    from={from}
                    currentData={currentData}
                    setShowRowsDropdown={setShowRowsDropdown}
                    showRowsDropdown={showRowsDropdown}
                    noOfRows={noOfRows}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setPage={setPage}
                    setNoOfRows={setNoOfRows}
                  />
                ) : (
                  <></>
                );
              }}
            />
          </div>
        </div>
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Msa;
